import { Drawer } from "antd";
import React, { useEffect, useState } from "react";
import { allActions } from "../../../Redux/myActions";
import { useDispatch, useSelector } from "react-redux";
import FlyerTemplateForm from "./FlyerTemplateForm";
import PreviewFlyerTemplate from "./PreviewFlyerTemplate";
import FlyerTemplateOptionModal from "./FlyerTemplateOptionModal";

export default function FlyerTemplateDrawer({
  postId,
  setIsTemplateOpen,
  isTemplateOpen,
}) {
  const { profileData } = useSelector((store) => store?.ProfileReducer);
  const { postEditFormData } = useSelector((store) => store?.PropertyReducer);

  const dispatch = useDispatch();
  const [current, setCurrent] = useState(0);
  const [imageList, setImageList] = useState([]);
  const [templateImageNo, setTemplateImageNo] = useState("");
  const [formData, setFormData] = useState({
    suburb: "",
    property_street_name: "",
    no_room: "",
    no_of_bathroom: "",
    no_of_car_space: "",
    phone: "",
    description: "",
    property_image: "",
    agent_list: [],
    property_type: "",
    logo: "",
  });

  useEffect(() => {
    profileData &&
      setFormData((prev) => {
        return { ...prev, logo: profileData?.company_logo };
      });

    postEditFormData &&
      setFormData((prev) => {
        return {
          ...prev,
          suburb: postEditFormData?.post?.suburb,
          property_street_name: postEditFormData?.post?.property_street_name,
          no_room: postEditFormData?.post?.no_room,
          no_of_bathroom: postEditFormData?.post?.no_of_bathroom,
          no_of_car_space: postEditFormData?.post?.no_of_car_space,
          phone: Number(postEditFormData?.post?.phone),
          description: postEditFormData?.post?.description,
          agent_list: postEditFormData?.form_fields?.static_fields
            ?.filter((item) => item?.attr?.label === "Agent")
            ?.map((item) => item?.attr?.value)
            ?.flat(),
          property_image: postEditFormData?.images,
          property_type: postEditFormData?.post?.property_category,
        };
      });
  }, [profileData, postEditFormData]);

  useEffect(() => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: `ads_edit_form/${postId}?api_from=classiEstate`,
          attempt: "FETCH_POST_EDIT_FORM_REQUEST_ATTEMPT",
          success: "FETCH_POST_EDIT_FORM_REQUEST_SUCCESS",
          failure: "FETCH_POST_EDIT_FORM_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );

    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "profile_new",
          attempt: "PROFILE_REQUEST_ATTEMPT",
          success: "PROFILE_REQUEST_SUCCESS",
          failure: "PROFILE_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  }, []);

  const onClose = () => {
    setIsTemplateOpen(false);
    setTemplateImageNo(0);
  };

  const formlist = [
    <FlyerTemplateOptionModal
      setCurrent={setCurrent}
      setTemplateImageNo={setTemplateImageNo}
      templateImageNo={templateImageNo}
    />,
    <FlyerTemplateForm
      formData={formData}
      templateImageNo={templateImageNo}
      setCurrent={setCurrent}
      setFormData={setFormData}
      imageList={imageList}
      setImageList={setImageList}
    />,
    <PreviewFlyerTemplate
      formData={formData}
      imageList={imageList}
      setFormData={setFormData}
      setCurrent={setCurrent}
    />,
  ];

  return (
    <Drawer
      title={`${templateImageNo} Image Flyer Template`}
      placement="right"
      onClose={onClose}
      open={isTemplateOpen}
      width="1200"
    >
      <div style={{ margin: "0 6rem" }}>
        {formData?.property_type && formlist[current]}
      </div>
    </Drawer>
  );
}
