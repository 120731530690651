import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  AreaChartOutlined,
  SettingOutlined,
  UserOutlined,
  StarOutlined,
  HomeOutlined,
  MailOutlined,
  CreditCardOutlined,
  LockOutlined,
  PlusSquareOutlined,
  UsergroupAddOutlined,
  LogoutOutlined,
  BellOutlined,
  MenuOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Drawer,
  Dropdown,
  Layout,
  Menu,
  Spin,
} from "antd";
import { useCallback, useRef, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { allActions } from "../Redux/myActions";
import { useDispatch, connect } from "react-redux";
import { IsScrollHeight } from "../Helpers/FrontendHelper";

import logo2 from "../assets/classiEstateAdmin1.png";
import logo3 from "../assets/classiEstateAdmin3.png";
import logo from "../assets/classiEstateAdmin1.png";
import useNotificationHook from "../hooks/useNotificationHook";

const { Header, Sider, Content } = Layout;
const link = [
  { propertyStatus: "all" },
  { propertyStatus: "featured" },
  { propertyStatus: "active" },
  { propertyStatus: "inactive" },
];

const mapStateToProps = (store) => {
  const { ProfileReducer } = store;
  return { ProfileReducer };
};
const userData = JSON.parse(localStorage.getItem("realestatedata"));

const DashboardLayout = ({ children, ProfileReducer }) => {
  const isSticky = IsScrollHeight();
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openNoti, setOpenNoti] = useState(false);
  const [activeNavbar, setActiveNavbar] = useState();

  const [openDrawer, setOpenDrawer] = useState(false);
  const { notificationList, notificationloading, profileImage, profileData } =
    ProfileReducer;

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  //notification
  const [notification, setNotification] = useState([]);
  const [pageNumber, serPageNumber] = useState(1);
  const observer = useRef();

  useNotificationHook(setNotification, pageNumber);

  const lastNotificationRef = useCallback(
    (node) => {
      observer.current && observer?.current?.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0]?.isIntersecting && notificationList?.has_next_page) {
          serPageNumber((prev) => prev + 1);
        }
      });
      node && observer?.current?.observe(node);
    },
    [notificationList?.has_next_page]
  );

  const link = [
    { propertyStatus: "all" },
    { propertyStatus: "featured" },
    { propertyStatus: "active" },
    { propertyStatus: "inactive" },
  ];

  const handleNotificationClick = (data) => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "seen_notifications?api_from=classiEstate",
          attempt: "SEEN_NOTIFICATION_REQUEST_ATTEMPT",
          success: "SEEN_NOTIFICATION_REQUEST_SUCCESS",
          failure: "SEEN_NOTIFICATION_REQUEST_FAILURE",
          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
    switch (data?.notification_type) {
      case "ADD_ADS":
        navigate("/my-property/all");
        break;
      case "find_agent_equiry":
        navigate("/find-agent-inquiry/inquiry-list");
        break;
      case "OFFER":
        navigate("/offer-message/offer-received");
        break;
      case "OFFER_REPLY":
        const record = data?.notification_type_id?.split("#");
        navigate("/offer-message/offer-received");
        break;
      default:
    }
  };

  const handleLogout = () => {
    navigate("/");
    localStorage?.clear();
  };

  const handleSetSortValue = () => {
    localStorage.setItem("sortValue", "");
  };

  const handleViewNotification = () => {
    setOpenNoti((prev) => !prev);
  };

  const items = [
    {
      key: "2",
      icon: <SettingOutlined />,
      label: <NavLink to="/profile-setting">Profile Settings</NavLink>,
    },
    {
      key: "11",
      icon: <LogoutOutlined />,
      label: <span onClick={handleLogout}>Logout</span>,
    },
  ];

  return (
    <Layout>
      <Sider
        className="dashboard--slider sider"
        style={{
          backgroundColor: "white",
          height: "100vh",
          overflowY: "scroll",
          overflowX: "hidden",
          position: "sticky",
          top: 0,
          left: 0,
        }}
        trigger={null}
        collapsible
        collapsed={collapsed}
      >
        <div className="dashboard--logo">
          {collapsed ? (
            <img
              onClick={() => navigate("/dashboard")}
              src={logo2}
              style={{
                padding: "0.3rem",
                width: "80px",
                height: "auto",
                cursor: "pointer",
              }}
              alt="classiEstate"
            />
          ) : (
            <img
              onClick={() => navigate("/dashboard")}
              style={{
                padding: "0.3rem",
                width: "250px",
                height: "auto",
                cursor: "pointer",
              }}
              src={logo3}
              alt="classiEstateAdmin"
            />
          )}
        </div>
        <div className="demo-logo-vertical" />
        <Menu
          theme="light"
          mode="inline"
          style={{
            backgroundColor: "white",
            color: "black",
          }}
          items={[
            {
              className: activeNavbar === 1 ? "active--navbar" : "",
              key: "1",
              icon: <AreaChartOutlined />,
              label: (
                <NavLink
                  className={({ isActive }) => isActive && setActiveNavbar(1)}
                  to="/dashboard"
                >
                  Insights
                </NavLink>
              ),
            },
            {
              className: activeNavbar === 2 ? "active--navbar" : "",
              key: "2",
              icon: <SettingOutlined />,
              label: (
                <NavLink
                  className={({ isActive }) => isActive && setActiveNavbar(2)}
                  to="/profile-setting"
                >
                  Profile Settings
                </NavLink>
              ),
            },
            {
              key: "5",
              icon: <HomeOutlined />,

              label: "My Properties",
              children: [
                {
                  className: activeNavbar === 501 ? "active--navbar" : "",
                  key: "501",
                  label: (
                    <NavLink
                      activeClassName="active"
                      to={`/my-property/${link[0].propertyStatus}`}
                      className={({ isActive }) =>
                        isActive && setActiveNavbar(501)
                      }
                      onClick={handleSetSortValue}
                    >
                      All Properties
                    </NavLink>
                  ),
                },
                {
                  key: "502",
                  className: activeNavbar === 502 ? "active--navbar" : "",
                  label: (
                    <NavLink
                      onClick={handleSetSortValue}
                      activeClassName="active"
                      className={({ isActive }) =>
                        isActive && setActiveNavbar(502)
                      }
                      to={`/my-property/${link[1].propertyStatus}`}
                    >
                      Featured Properties
                    </NavLink>
                  ),
                },
                {
                  key: "503",
                  className: activeNavbar === 503 ? "active--navbar" : "",
                  label: (
                    <NavLink
                      activeClassName="active"
                      onClick={handleSetSortValue}
                      to={`/my-property/${link[2].propertyStatus}`}
                      className={({ isActive }) =>
                        isActive && setActiveNavbar(503)
                      }
                    >
                      Active Properties
                    </NavLink>
                  ),
                },
                {
                  key: "504",
                  className: activeNavbar === 504 ? "active--navbar" : "",
                  label: (
                    <NavLink
                      className={({ isActive }) =>
                        isActive && setActiveNavbar(504)
                      }
                      activeClassName="active"
                      onClick={handleSetSortValue}
                      to={`/my-property/${link[3].propertyStatus}`}
                    >
                      Inactive Properties
                    </NavLink>
                  ),
                },
              ],
            },
            {
              key: "6",

              icon: <MailOutlined />,
              label: "Offers/Messages",
              children: [
                {
                  key: "601",
                  className: activeNavbar === 601 ? "active--navbar" : "",
                  label: (
                    <NavLink
                      className={({ isActive }) =>
                        isActive && setActiveNavbar(601)
                      }
                      to={"/offer-message/offer-received"}
                    >
                      Offer Received
                    </NavLink>
                  ),
                },
                {
                  key: "603",
                  className: activeNavbar === 603 ? "active--navbar" : "",
                  label: (
                    <NavLink
                      className={({ isActive }) =>
                        isActive && setActiveNavbar(603)
                      }
                      to={"/offer-message/agent-inquiry"}
                    >
                      Agent Inquiry
                    </NavLink>
                  ),
                },
                {
                  key: "604",
                  className: activeNavbar === 604 ? "active--navbar" : "",
                  label: (
                    <NavLink
                      className={({ isActive }) =>
                        isActive && setActiveNavbar(604)
                      }
                      to={"/offer-message/agency-inquiry"}
                    >
                      Agency Inquiry
                    </NavLink>
                  ),
                },
              ],
            },
            {
              key: "10",
              icon: <UsergroupAddOutlined />,
              label: "Find Agent Inquiry",
              children: [
                {
                  key: "1001",
                  className: activeNavbar === 1001 ? "active--navbar" : "",
                  label: (
                    <NavLink
                      className={({ isActive }) =>
                        isActive && setActiveNavbar(1001)
                      }
                      to={"/find-agent-inquiry/setting"}
                    >
                      Setting
                    </NavLink>
                  ),
                },
                {
                  key: "1002",
                  className: activeNavbar === 1002 ? "active--navbar" : "",
                  label: (
                    <NavLink
                      className={({ isActive }) =>
                        isActive && setActiveNavbar(1002)
                      }
                      to={"/find-agent-inquiry/inquiry-credits"}
                    >
                      Inquiry Credits
                    </NavLink>
                  ),
                },
                {
                  key: "1003",
                  className: activeNavbar === 1003 ? "active--navbar" : "",
                  label: (
                    <NavLink
                      className={({ isActive }) =>
                        isActive && setActiveNavbar(1003)
                      }
                      to={"/find-agent-inquiry/inquiry-credit-list"}
                    >
                      Inquiry Credit List
                    </NavLink>
                  ),
                },
                {
                  key: "1004",
                  className: activeNavbar === 1004 ? "active--navbar" : "",
                  label: (
                    <NavLink
                      className={({ isActive }) =>
                        isActive && setActiveNavbar(1004)
                      }
                      to={"/find-agent-inquiry/inquiry-list"}
                    >
                      Inquiry List
                    </NavLink>
                  ),
                },
              ],
            },
            {
              key: "3",
              className: activeNavbar === 3 ? "active--navbar" : "",
              icon: <UserOutlined />,
              label: (
                <NavLink
                  className={({ isActive }) => isActive && setActiveNavbar(3)}
                  to="/agents"
                >
                  Agents
                </NavLink>
              ),
            },
            {
              key: "4",
              className: activeNavbar === 4 ? "active--navbar" : "",
              icon: <StarOutlined />,
              style: {
                color: "black",
              },
              label: (
                <NavLink
                  className={({ isActive }) => isActive && setActiveNavbar(4)}
                  to="/coupon"
                >
                  Coupon
                </NavLink>
              ),
            },
            {
              key: "7",
              className: activeNavbar === 7 ? "active--navbar" : "",
              icon: <CreditCardOutlined />,
              label: (
                <NavLink
                  className={({ isActive }) => isActive && setActiveNavbar(7)}
                  to="/payment"
                >
                  Payments
                </NavLink>
              ),
            },
            {
              key: "9",
              icon: <PlusSquareOutlined />,
              label: "Referral Management",
              children: [
                {
                  key: "901",
                  className: activeNavbar === 901 ? "active--navbar" : "",
                  label: (
                    <NavLink
                      className={({ isActive }) =>
                        isActive && setActiveNavbar(901)
                      }
                      to={"/referral/invite-friend"}
                    >
                      Invite Friend
                    </NavLink>
                  ),
                },
                {
                  key: "902",
                  className: activeNavbar === 902 ? "active--navbar" : "",
                  label: (
                    <NavLink
                      className={({ isActive }) =>
                        isActive && setActiveNavbar(902)
                      }
                      to={"/referral/ref-dashboard"}
                    >
                      Referral Dashboard
                    </NavLink>
                  ),
                },
              ],
            },
            {
              key: "8",
              className: activeNavbar === 8 ? "active--navbar" : "",
              icon: <LockOutlined />,
              label: (
                <NavLink
                  className={({ isActive }) => isActive && setActiveNavbar(8)}
                  to="/privacy-setting"
                >
                  Privacy Settings
                </NavLink>
              ),
            },
            {
              className: activeNavbar === 12 ? "active--navbar" : "",
              key: "12",
              icon: <SettingOutlined />,
              label: (
                <NavLink
                  className={({ isActive }) => isActive && setActiveNavbar(12)}
                  to="/integration"
                >
                  Integration
                </NavLink>
              ),
            },
          ]}
        />
      </Sider>
      <Layout>
        <Header
          // className={isSticky ? "sticky" : "layout--header"}
          className="layout--header"
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: "1.5rem",
              width: 64,
              height: 64,
            }}
            className="show--hide--component"
          />
          <MenuOutlined
            onClick={() => setOpenDrawer(true)}
            className="show--hide--menu"
          />
          {/* tab--mobile--drawer */}
          <TabMobileDrawer
            activeNavbar={activeNavbar}
            setActiveNavbar={setActiveNavbar}
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
            handleLogout={handleLogout}
            handleViewNotification={handleViewNotification}
            handleSetSortValue={handleSetSortValue}
          />
          <img
            onClick={() => navigate("/dashboard")}
            style={{
              width: "10rem",
              height: "4rem",
              objectFit: "contain",
              cursor: "pointer",
            }}
            className="show--hide--image"
            src={logo3}
            alt="classiEstateAdmin"
          />

          <div className="show--hide--menu">
            <NavLink to="/ad-property">
              <HomeOutlined
                style={{
                  fontSize: "1.5rem",
                  cursor: "pointer",
                  color: "#0b3e75ce",
                }}
              />
            </NavLink>
            <Badge
              size="default"
              count={
                notificationList?.no_of_unseen
                  ? notificationList?.no_of_unseen
                  : 0
              }
            >
              <BellOutlined
                onClick={handleViewNotification}
                style={{
                  fontSize: "1.5rem",
                  cursor: "pointer",
                  color: "#0b3e75ce",
                }}
              />
            </Badge>

            <Dropdown
              menu={{
                items,
              }}
              placement="bottomRight"
              arrow
            >
              {profileImage ? (
                <img
                  style={{
                    width: "2rem",
                    height: "2rem",
                    borderRadius: "100%",
                    objectFit: "contain",
                    cursor: "pointer",
                  }}
                  src={profileImage}
                  alt=""
                />
              ) : (
                <Avatar style={{ cursor: "pointer", fontSize: "1rem" }}>
                  {profileData?.first_name?.length > 0
                    ? profileData?.first_name[0]
                    : ""}
                </Avatar>
              )}
            </Dropdown>
          </div>
          <div className="post--notification show--hide--component">
            <NavLink className="show--hide--component" to="/ad-property">
              <span
                className="button--modal--home"
                style={{
                  height: "2.2rem",
                  cursor: "pointer",
                  borderRadius: "2rem",
                  fontWeight: "600",
                }}
              >
                <HomeOutlined style={{ fontSize: "1rem" }} /> Post An Ad
              </span>
            </NavLink>
            <Badge
              size="default"
              count={
                notificationList?.no_of_unseen
                  ? notificationList?.no_of_unseen
                  : 0
              }
            >
              <span
                onClick={handleViewNotification}
                className="notification--icon"
                style={{ cursor: "pointer" }}
              >
                <BellOutlined
                  style={{
                    fontSize: "1.1rem",
                    color: "#0b3e75ce",
                    cursor: "pointer",
                    fontWeight: "600",
                  }}
                />
              </span>
            </Badge>

            <Dropdown
              menu={{
                items,
              }}
              placement="bottomRight"
              arrow
            >
              {profileImage ? (
                <img
                  style={{
                    width: "2.5rem",
                    height: "2.5rem",
                    borderRadius: "100%",
                    objectFit: "contain",
                    background: "#D8D9DA",
                    cursor: "pointer",
                  }}
                  src={profileImage}
                  alt=""
                />
              ) : (
                <Avatar style={{ cursor: "pointer", fontSize: "1rem" }}>
                  {profileData?.first_name?.length > 0
                    ? profileData?.first_name[0]
                    : ""}
                </Avatar>
              )}
            </Dropdown>
          </div>
        </Header>
        <div
          style={{
            width: "100%",
            minHeight: "92vh",
          }}
        >
          <div
            className={
              openNoti
                ? "notification--container--active overflow--scroll"
                : "notification--container--icon"
            }
          >
            <div className="small--device">
              <CloseCircleOutlined
                onClick={() => setOpenNoti(false)}
                className="notifi--close--button"
              />
            </div>
            {notification.length > 0
              ? notification.map((item, index) => {
                  if (notification?.length === index + 1) {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "1rem",
                          alignItems: "center",
                          padding: "0.5rem",
                          borderRadius: "2px",
                          background: "#F5F5F5",
                          cursor: "pointer",
                          width: "100%",
                        }}
                        ref={lastNotificationRef}
                        onClick={() => handleNotificationClick(item)}
                      >
                        <img
                          src={logo}
                          alt=""
                          style={{
                            height: "3rem",
                            width: "3rem",
                            objectFit: "contain",
                          }}
                        />
                        <span>{item?.body}</span>
                      </div>
                    );
                  } else {
                    return (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "1rem",
                          alignItems: "center",
                          padding: "0.5rem",
                          borderRadius: "2px",
                          background: "#F5F5F5",
                          cursor: "pointer",
                          width: "100%",
                        }}
                        onClick={() => handleNotificationClick(item)}
                      >
                        <img
                          src={logo}
                          alt=""
                          style={{
                            height: "3rem",
                            width: "3rem",
                            objectFit: "contain",
                          }}
                        />
                        <span>{item?.body}</span>
                      </div>
                    );
                  }
                })
              : !notificationloading && (
                  <span style={{ color: "black" }}> No Notification</span>
                )}
            {
              notificationloading && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  {" "}
                  <Spin indicator={antIcon} />;
                </div>
              )
              // <div>loading...</div>
            }
          </div>
          <div
            onClick={() => setOpenNoti(false)}
            className={openNoti ? "hidden--conatiner" : ""}
          ></div>
          <Content className="style--content--container">{children}</Content>
        </div>
      </Layout>
    </Layout>
  );
};
export default connect(mapStateToProps)(DashboardLayout);

export const TabMobileDrawer = ({
  openDrawer,
  setOpenDrawer,
  handleSetSortValue,
  activeNavbar,
  setActiveNavbar,
}) => {
  const onClose = () => {
    setOpenDrawer(false);
  };

  return (
    <Drawer title={null} placement="left" onClose={onClose} open={openDrawer}>
      <Menu
        theme="light"
        mode="inline"
        style={{
          backgroundColor: "white",
          color: "black",
        }}
        items={[
          {
            className: activeNavbar === 1 ? "active--navbar" : "",
            key: "1",
            icon: <AreaChartOutlined />,
            label: (
              <NavLink
                className={({ isActive }) => isActive && setActiveNavbar(1)}
                to="/dashboard"
              >
                Insights
              </NavLink>
            ),
          },
          {
            className: activeNavbar === 2 ? "active--navbar" : "",
            key: "2",
            icon: <SettingOutlined />,
            label: (
              <NavLink
                className={({ isActive }) => isActive && setActiveNavbar(2)}
                to="/profile-setting"
              >
                Profile Settings
              </NavLink>
            ),
          },
          {
            key: "5",
            icon: <HomeOutlined />,
            label: "My Properties",
            children: [
              {
                className: activeNavbar === 501 ? "active--navbar" : "",
                key: "501",
                label: (
                  <NavLink
                    activeClassName="active"
                    to={`/my-property/${link[0].propertyStatus}`}
                    className={({ isActive }) =>
                      isActive && setActiveNavbar(501)
                    }
                    onClick={handleSetSortValue}
                  >
                    All Properties
                  </NavLink>
                ),
              },
              {
                key: "502",
                className: activeNavbar === 502 ? "active--navbar" : "",
                label: (
                  <NavLink
                    onClick={handleSetSortValue}
                    activeClassName="active"
                    className={({ isActive }) =>
                      isActive && setActiveNavbar(502)
                    }
                    to={`/my-property/${link[1].propertyStatus}`}
                  >
                    Featured Properties
                  </NavLink>
                ),
              },
              {
                key: "503",
                className: activeNavbar === 503 ? "active--navbar" : "",
                label: (
                  <NavLink
                    activeClassName="active"
                    onClick={handleSetSortValue}
                    to={`/my-property/${link[2].propertyStatus}`}
                    className={({ isActive }) =>
                      isActive && setActiveNavbar(503)
                    }
                  >
                    Active Properties
                  </NavLink>
                ),
              },
              {
                key: "504",
                className: activeNavbar === 504 ? "active--navbar" : "",
                label: (
                  <NavLink
                    className={({ isActive }) =>
                      isActive && setActiveNavbar(504)
                    }
                    activeClassName="active"
                    onClick={handleSetSortValue}
                    to={`/my-property/${link[3].propertyStatus}`}
                  >
                    Inactive Properties
                  </NavLink>
                ),
              },
            ],
          },
          {
            key: "6",

            icon: <MailOutlined />,
            label: "Offers/Messages",
            children: [
              {
                key: "601",
                className: activeNavbar === 601 ? "active--navbar" : "",
                label: (
                  <NavLink
                    className={({ isActive }) =>
                      isActive && setActiveNavbar(601)
                    }
                    to={"/offer-message/offer-received"}
                  >
                    Offer Received
                  </NavLink>
                ),
              },
              // {
              //   key: "602",
              //   label: (
              //     <NavLink to={"/offer-message/offer-sent"}>
              //       Offer Sent
              //     </NavLink>
              //   ),
              // },
              {
                key: "603",
                className: activeNavbar === 603 ? "active--navbar" : "",
                label: (
                  <NavLink
                    className={({ isActive }) =>
                      isActive && setActiveNavbar(603)
                    }
                    to={"/offer-message/agent-inquiry"}
                  >
                    Agent Inquiry
                  </NavLink>
                ),
              },
              {
                key: "604",
                className: activeNavbar === 604 ? "active--navbar" : "",
                label: (
                  <NavLink
                    className={({ isActive }) =>
                      isActive && setActiveNavbar(604)
                    }
                    to={"/offer-message/agency-inquiry"}
                  >
                    Agency Inquiry
                  </NavLink>
                ),
              },
            ],
          },
          {
            key: "10",
            icon: <UsergroupAddOutlined />,
            label: "Find Agent Inquiry",
            children: [
              {
                key: "1001",
                className: activeNavbar === 1001 ? "active--navbar" : "",
                label: (
                  <NavLink
                    className={({ isActive }) =>
                      isActive && setActiveNavbar(1001)
                    }
                    to={"/find-agent-inquiry/setting"}
                  >
                    Setting
                  </NavLink>
                ),
              },
              {
                key: "1002",
                className: activeNavbar === 1002 ? "active--navbar" : "",
                label: (
                  <NavLink
                    className={({ isActive }) =>
                      isActive && setActiveNavbar(1002)
                    }
                    to={"/find-agent-inquiry/inquiry-credits"}
                  >
                    Inquiry Credits
                  </NavLink>
                ),
              },
              {
                key: "1003",
                className: activeNavbar === 1003 ? "active--navbar" : "",
                label: (
                  <NavLink
                    className={({ isActive }) =>
                      isActive && setActiveNavbar(1003)
                    }
                    to={"/find-agent-inquiry/inquiry-credit-list"}
                  >
                    Inquiry Credit List
                  </NavLink>
                ),
              },
              {
                key: "1004",
                className: activeNavbar === 1004 ? "active--navbar" : "",
                label: (
                  <NavLink
                    className={({ isActive }) =>
                      isActive && setActiveNavbar(1004)
                    }
                    to={"/find-agent-inquiry/inquiry-list"}
                  >
                    Inquiry List
                  </NavLink>
                ),
              },
            ],
          },

          {
            key: "3",
            className: activeNavbar === 3 ? "active--navbar" : "",
            icon: <UserOutlined />,
            label: (
              <NavLink
                className={({ isActive }) => isActive && setActiveNavbar(3)}
                to="/agents"
              >
                Agents
              </NavLink>
            ),
          },
          {
            key: "4",
            className: activeNavbar === 4 ? "active--navbar" : "",
            icon: <StarOutlined />,
            style: {
              color: "black",
            },
            label: (
              <NavLink
                className={({ isActive }) => isActive && setActiveNavbar(4)}
                to="/coupon"
              >
                Coupon
              </NavLink>
            ),
          },

          {
            key: "7",
            className: activeNavbar === 7 ? "active--navbar" : "",
            icon: <CreditCardOutlined />,
            label: (
              <NavLink
                className={({ isActive }) => isActive && setActiveNavbar(7)}
                to="/payment"
              >
                Payments
              </NavLink>
            ),
          },
          {
            key: "9",
            icon: <PlusSquareOutlined />,
            label: "Referral Management",
            children: [
              {
                key: "901",
                className: activeNavbar === 901 ? "active--navbar" : "",
                label: (
                  <NavLink
                    className={({ isActive }) =>
                      isActive && setActiveNavbar(901)
                    }
                    to={"/referral/invite-friend"}
                  >
                    Invite Friend
                  </NavLink>
                ),
              },
              {
                key: "902",
                className: activeNavbar === 902 ? "active--navbar" : "",
                label: (
                  <NavLink
                    className={({ isActive }) =>
                      isActive && setActiveNavbar(902)
                    }
                    to={"/referral/ref-dashboard"}
                  >
                    Referral Dashboard
                  </NavLink>
                ),
              },
            ],
          },
          {
            key: "8",
            className: activeNavbar === 8 ? "active--navbar" : "",
            icon: <LockOutlined />,
            label: (
              <NavLink
                className={({ isActive }) => isActive && setActiveNavbar(8)}
                to="/privacy-setting"
              >
                Privacy Settings
              </NavLink>
            ),
          },
          {
            className: activeNavbar === 12 ? "active--navbar" : "",
            key: "12",
            icon: <SettingOutlined />,
            label: (
              <NavLink
                className={({ isActive }) => isActive && setActiveNavbar(12)}
                to="/integration"
              >
                Integration
              </NavLink>
            ),
          },
        ]}
      />
    </Drawer>
  );
};
