import { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Divider, Steps } from "antd";
import { Button, Form, Drawer } from "antd";

import PostAnAdDrawer from "../Drawer/PostAnAdDrawer";
import PostAnForm1 from "../multipartForm/PostAnForm1";
import PostAdForm2 from "../multipartForm/PostAdForm2";
import ScreenSize from "../screenSizeReader/ScreenSize";
import PostAdForm3 from "../multipartForm/PostAdForm3";
import { allActions } from "../../Redux/myActions";
import CurrentScreenSize from "../screenSizeReader/CurrentScreenSize";
import MainLoader from "../loader/MainLoader";

const mapStateToProps = (store) => {
  const PropertyReducer = store?.PropertyReducer;
  return { PropertyReducer };
};

const PostAnAd = ({ open, setOpen, PropertyReducer }) => {
  const realestatedata = JSON.parse(localStorage.getItem("realestatedata"));
  const isLarge = CurrentScreenSize();
  const dispatch = useDispatch();
  const [currentState, setCurrentState] = useState(false);
  const [locaton, setLocation] = useState("");
  const drawerWidth = ScreenSize({ width: "85%" });
  const { postAdForm, adloading, loading, mediaType } = PropertyReducer;
  const { categories } = postAdForm;
  const { static_fields, fields } = postAdForm;
  const [form] = Form.useForm();
  const [formState, setFormState] = useState(1);
  const [mediaFile, setMediaFile] = useState([]);

  const [postImages, setImag] = useState([]);
  const [floor_plans, setImagList] = useState([]);

  useEffect(() => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "/media_type",
          attempt: "FETCH_MEDIA_REQUEST_ATTEMPT",
          success: "FETCH_MEDIA_REQUEST_SUCCESS",
          failure: "FETCH_MEDIA_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  }, []);

  const onClose = () => {
    setOpen(false);
  };

  const StepComponent = () => (
    <Steps
      size="small"
      current={5}
      items={[
        ...(postAdForm &&
          categories?.titles?.map((item, index) => {
            return {
              title: <span style={{ fontWeight: 600 }}>{item}</span>,
              status: "finish",
            };
          })),
        {
          title: (
            <span
              style={{
                color: "blue",
                cursor: "pointer",
                fontWeight: 600,
              }}
              onClick={() => setCurrentState(false)}
            >
              (Change)
            </span>
          ),
        },
      ]}
    />
  );

  const handleSubmitAdPost = (values) => {
    const inspection_date = values?.inspection_date_times?.map((date_time) => {
      const selectedDate = date_time?.date.format("YYYY-MM-DD");
      return selectedDate;
    });

    const start_hr = values?.inspection_date_times?.map((date_time) => {
      const selectedTime = new Date(date_time?.start_time);
      const hour = selectedTime.getHours();
      return hour;
    });

    const start_min = values?.inspection_date_times?.map((date_time) => {
      const selectedTime = new Date(date_time?.start_time);
      const min = selectedTime.getMinutes();
      return min;
    });

    const start_time = values?.inspection_date_times?.map((date_time) => {
      const selectedTime = new Date(date_time?.start_time);
      const min = selectedTime.getMinutes();
      const hour = selectedTime.getHours();
      return `${hour} : ${min} `;
    });

    const end_hr = values?.inspection_date_times?.map((date_time) => {
      const selectedTime = new Date(date_time?.end_time);
      const hour = selectedTime.getHours();
      return hour;
    });

    const end_min = values?.inspection_date_times?.map((date_time) => {
      const selectedTime = new Date(date_time?.end_time);
      const min = selectedTime.getMinutes();
      return min;
    });

    const end_time = values?.inspection_date_times?.map((date_time) => {
      const selectedTime = new Date(date_time?.end_time);
      const min = selectedTime.getMinutes();
      const hour = selectedTime.getHours();
      return `${hour} : ${min} `;
    });

    const temp_prop_image = postImages?.map((item) => {
      return {
        imagename: item?.image_name,
        thumbnail: item?.image_thumnail_name,
      };
    });

    const temp_room_image = floor_plans?.map((item) => {
      return {
        imagename: item?.image_name,
        thumbnail: item?.image_thumnail_name,
      };
    });

    dispatch(
      allActions(
        {
          form_id: postAdForm?.form_id,
          ad_title: values?.title,
          price_type: values?.price_type,
          ad_price: values?.price ? values?.price : 0,
          to_ad_price: values?.to_price ? values?.to_price : 0,
          description: values?.description,
          sell_type: values?.sell_type,
          address: values?.address,
          ad_address: values?.address,
          location: values?.property_location,
          name: `${values?.first_name} ${values?.last_name}`,
          email: values?.email,
          phone: values?.phone,
          show_phone: "1",
          show_price: values?.show_price,
          postcode: values?.property_postcode,
          property_postcode: values?.property_postcode,
          property_house_number: values?.property_house_number,
          property_state: values?.property_state,
          property_street_name: values?.property_street_name,
          property_suburb: values?.property_suburb,
          ad_status: values?.ad_status,
          agents: values?.agents,
          sale_by: "1",
          display_address: values?.display_address,
          property_type: values?.property_type,
          Property_condition: values?.Property_condition,
          no_room: values?.no_room,
          Number_of_Bathrooms: values?.Number_of_Bathrooms,
          no_of_car_space: values?.no_of_car_space,
          Parking: values?.Parking,
          postImages: temp_prop_image,
          floor_plans: temp_room_image,
          is_furnished: values?.Is_Furnished,

          inspection_date: inspection_date,
          ticket_start_date: values?.availableFrom?.format("YYYY-MM-DD"),
          start_hr: start_hr,
          start_min: start_min,
          start_time: start_time,
          end_hr: end_hr,
          end_min: end_min,
          end_time: end_time,
          post_indoor_features: values?.post_indoor_features,
          post_outdoor_features: values?.post_outdoor_features,
          house_area: values?.house_area,
          area: values?.area,

          price_type2: values?.price_type2 ? values?.price_type2 : "Month",
          security_bound: values?.security_bound,
          txt_media_type: mediaFile,
          app_name: "CLASSIESTATE",
        },
        {
          method: "post",
          endPoint: "ads_form?slug=real-estate&api_from=classiEstate",
          attempt: "ADD_AD_FORM_REQUEST_ATTEMPT",
          success: "ADD_AD_FORM_REQUEST_SUCCESS",
          failure: "ADD_AD_FORM_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {
            setTimeout(() => {
              setOpen(false);
            }, 1000);
          },
          failureInternalState: () => {},
        }
      )
    );
  };

  return (
    <>
      {adloading && <MainLoader />}
      <Drawer
        title={currentState ? "Ad Details" : "Select Category Ads"}
        placement="right"
        onClose={onClose}
        open={open}
        width={drawerWidth}
      >
        {!currentState && (
          <PostAnAdDrawer open={open} setCurrentState={setCurrentState} />
        )}
        {currentState && (
          <div className="post--ad--form--container">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                margin: "0 1.5rem",
              }}
            >
              <h3>Selected Category</h3>
              <div className="selected--category--container--header">
                {StepComponent()}
              </div>
            </div>
            <div className="selected--category--container--body">
              <div className="selected--category--subcontainer--header">
                <h3>Form ID: #{postAdForm?.form_id} </h3>
                <h3>Property Type: {categories?.titles[1]} </h3>
              </div>
              <Divider style={{ margin: "1rem 0 0.5rem" }} />
              <div className="selected--category--subcontainer--body">
                {static_fields && (
                  <Form
                    initialValues={{
                      ad_status:
                        static_fields?.find(
                          (item) => item?.attr?.label === "Status"
                        ).childs[0].value ?? "",

                      first_name: realestatedata?.first_name,
                      last_name: realestatedata?.last_name,
                      email: realestatedata?.user_email,
                      phone: realestatedata?.user_mobile,
                      location: realestatedata?.street_address,
                    }}
                    labelAlign="left"
                    layout="vertical"
                    wrapperCol={{
                      span: 24,
                    }}
                    form={form}
                    onFinish={handleSubmitAdPost}
                    autoComplete="off"
                  >
                    <PostAnForm1
                      static_fields={static_fields}
                      form={form}
                      isLarge={isLarge}
                      setFormState={setFormState}
                      fields={fields}
                      setLocation={setLocation}
                    />

                    <Divider />
                    <PostAdForm2
                      form={form}
                      mediaFile={mediaFile}
                      setMediaFile={setMediaFile}
                      postImages={postImages}
                      setImag={setImag}
                      floor_plans={floor_plans}
                      setImagList={setImagList}
                      setFormState={setFormState}
                      isLarge={isLarge}
                    />

                    <Divider />
                    <PostAdForm3 isLarge={isLarge} form={form} />

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "1rem",
                        margin: "1rem 0",
                      }}
                    >
                      <Button
                        type="primary"
                        laoding={loading}
                        htmlType="submit"
                      >
                        {loading ? "Loading..." : "Submit"}
                      </Button>
                    </div>
                  </Form>
                )}
              </div>
            </div>
          </div>
        )}
      </Drawer>
    </>
  );
};
export default connect(mapStateToProps)(PostAnAd);
