import { Button } from "antd";
import React, { useState } from "react";
import SingleImageLayout from "./imageLayout/SingleImageLayout";
import TwoImageLayout from "./imageLayout/TwoImageLayout";
import ThreeImageLayout from "./imageLayout/ThreeImageLayout";
import FourImageLayout from "./imageLayout/FourImageLayout";
import { Document, Page, pdfjs } from "react-pdf";
import { PDFDocument, rgb } from "pdf-lib";
import { PDFViewer } from "@react-pdf/renderer";
import { TemplatePdf } from "./TemlatePdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function PreviewFlyerTemplate({
  formData,
  imageList,
  setFormData,
  setCurrent,
}) {
  const handleBack = () => {
    setFormData((prev) => prev);
    setCurrent((prev) => prev - 1);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        margin: "0 1rem",
      }}
    >

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0.2rem",
          backgroundColor: "black",
        }}
      >
        <div
          className="template--header"
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "1rem",
            height: "4rem",
            marginBottom: "0.2rem",
          }}
        >
          <img
            alt=""
            src={formData?.logo}
            style={{
              width: "4rem",
              height: "4rem",
              objectFit: "contain",
              border: "1px solid white",
            }}
          />
        </div>
        <div style={{ width: "100%" }}>
          {imageList?.length === 1 && (
            <SingleImageLayout imageList={imageList} />
          )}
          {imageList?.length === 2 && <TwoImageLayout imageList={imageList} />}
          {imageList?.length === 3 && (
            <ThreeImageLayout imageList={imageList} />
          )}
          {imageList?.length === 4 && <FourImageLayout imageList={imageList} />}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "0 1.5rem 1rem",
            gap: "0.5rem",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "flex-end",
              gap: "1rem",
            }}
          >
            <h1 style={{ color: "#eed988" }}>{formData?.suburb}</h1>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-end",
                color: "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "1rem",
                  padding: "1rem 1rem 0",
                  borderRight: "2px solid white",
                  borderLeft: "2px solid white",
                }}
              >
                <span>{formData?.no_room}</span>
                <span>ROOM</span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "1rem",
                  padding: "1rem 1rem 0",
                }}
              >
                <span>{formData?.no_of_bathroom}</span>
                <span>BATH</span>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  fontSize: "1rem",
                  padding: "1rem 1rem 0",
                  borderRight: "2px solid white",
                  borderLeft: "2px solid white",
                }}
              >
                <span>{formData?.no_of_car_space}</span>
                <span>CAR</span>
              </div>
            </div>
          </div>
          <h2 style={{ color: "white" }}>{formData?.property_street_name}</h2>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "2fr 1fr",
              gap: "1.5rem",
            }}
          >
            <h3 style={{ color: "white" }}>{formData?.description}</h3>
            <h2
              style={{
                color: "#eed988",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              For {formData?.property_type?.toUpperCase()}
            </h2>
          </div>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "2fr 1fr",
              gap: "1.5rem",
              marginTop: "1rem",
            }}
          >
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "0.5rem",
              }}
            >
              {formData?.agent_list?.map((item) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "1rem",
                  }}
                >
                  <img
                    src={item?.image_url}
                    alt=""
                    style={{
                      width: "4rem",
                      height: "4rem",
                      objectFit: "cover",
                    }}
                  />
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <h2
                      style={{
                        color: "#eed988",
                      }}
                    >
                      {item?.first_name} {item?.last_name}
                    </h2>
                    <h1
                      style={{
                        color: "white",
                      }}
                    >
                      {item?.phone}
                    </h1>
                  </div>
                </div>
              ))}
            </div>
            <h1
              style={{
                color: "#eed988",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              {formData?.phone}
            </h1>
          </div>
        </div>
      </div>

      <Button
        style={{
          width: "fit-content",
        }}
        type="primary"
        onClick={handleBack}
      >
        Back
      </Button>
    </div>
  );
}

// import { Button } from "antd";
// import React, { useState } from "react";
// import SingleImageLayout from "./imageLayout/SingleImageLayout";
// import TwoImageLayout from "./imageLayout/TwoImageLayout";
// import ThreeImageLayout from "./imageLayout/ThreeImageLayout";
// import FourImageLayout from "./imageLayout/FourImageLayout";
// import { Document, Page, pdfjs } from "react-pdf";
// import { PDFDocument, rgb } from "pdf-lib";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// export default function PreviewFlyerTemplate({
//   formData,
//   imageList,
//   setFormData,
//   setCurrent,
// }) {
//   const handleBack = () => {
//     setFormData((prev) => prev);
//     setCurrent((prev) => prev - 1);
//   };
//   return (
//     <div
//       style={{
//         display: "flex",
//         flexDirection: "column",
//         gap: "1rem",
//         margin: "0 1rem",
//       }}
//     >
//       <div
//         style={{
//           display: "flex",
//           flexDirection: "column",
//           padding: "0.2rem",
//           backgroundColor: "black",
//         }}
//       >
//         <div
//           className="template--header"
//           style={{
//             display: "flex",
//             flexDirection: "row",
//             gap: "1rem",
//             height: "4rem",
//             marginBottom: "0.2rem",
//           }}
//         >
//           <img
//             alt=""
//             src={formData?.logo}
//             style={{
//               width: "4rem",
//               height: "4rem",
//               objectFit: "contain",
//               border: "1px solid white",
//             }}
//           />
//         </div>
//         <div style={{ width: "100%" }}>
//           {imageList?.length === 1 && (
//             <SingleImageLayout imageList={imageList} />
//           )}
//           {imageList?.length === 2 && <TwoImageLayout imageList={imageList} />}
//           {imageList?.length === 3 && (
//             <ThreeImageLayout imageList={imageList} />
//           )}
//           {imageList?.length === 4 && <FourImageLayout imageList={imageList} />}
//         </div>
//         <div
//           style={{
//             display: "flex",
//             flexDirection: "column",
//             margin: "0 1.5rem 1rem",
//             gap: "0.5rem",
//           }}
//         >
//           <div
//             style={{
//               display: "flex",
//               flexDirection: "row",
//               justifyContent: "space-between",
//               alignItems: "flex-end",
//               gap: "1rem",
//             }}
//           >
//             <h1 style={{ color: "#eed988" }}>{formData?.suburb}</h1>
//             <div
//               style={{
//                 display: "flex",
//                 flexDirection: "row",
//                 alignItems: "flex-end",
//                 color: "white",
//               }}
//             >
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "center",
//                   justifyContent: "center",
//                   fontSize: "1rem",
//                   padding: "1rem 1rem 0",
//                   borderRight: "2px solid white",
//                   borderLeft: "2px solid white",
//                 }}
//               >
//                 <span>{formData?.no_room}</span>
//                 <span>ROOM</span>
//               </div>
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "center",
//                   justifyContent: "center",
//                   fontSize: "1rem",
//                   padding: "1rem 1rem 0",
//                 }}
//               >
//                 <span>{formData?.no_of_bathroom}</span>
//                 <span>BATH</span>
//               </div>
//               <div
//                 style={{
//                   display: "flex",
//                   flexDirection: "column",
//                   alignItems: "center",
//                   justifyContent: "center",
//                   fontSize: "1rem",
//                   padding: "1rem 1rem 0",
//                   borderRight: "2px solid white",
//                   borderLeft: "2px solid white",
//                 }}
//               >
//                 <span>{formData?.no_of_car_space}</span>
//                 <span>CAR</span>
//               </div>
//             </div>
//           </div>
//           <h2 style={{ color: "white" }}>{formData?.property_street_name}</h2>
//           <div
//             style={{
//               display: "grid",
//               gridTemplateColumns: "2fr 1fr",
//               gap: "1.5rem",
//             }}
//           >
//             <h3 style={{ color: "white" }}>{formData?.description}</h3>
//             <h2
//               style={{
//                 color: "#eed988",
//                 display: "flex",
//                 justifyContent: "flex-end",
//               }}
//             >
//               For {formData?.property_type?.toUpperCase()}
//             </h2>
//           </div>
//           <div
//             style={{
//               display: "grid",
//               gridTemplateColumns: "2fr 1fr",
//               gap: "1.5rem",
//               marginTop: "1rem",
//             }}
//           >
//             <div
//               style={{
//                 display: "grid",
//                 gridTemplateColumns: "1fr 1fr",
//                 gap: "0.5rem",
//               }}
//             >
//               {formData?.agent_list?.map((item) => (
//                 <div
//                   style={{
//                     display: "flex",
//                     flexDirection: "row",
//                     gap: "1rem",
//                   }}
//                 >
//                   <img
//                     src={item?.image_url}
//                     alt=""
//                     style={{
//                       width: "4rem",
//                       height: "4rem",
//                       objectFit: "cover",
//                     }}
//                   />
//                   <div
//                     style={{
//                       display: "flex",
//                       flexDirection: "column",
//                     }}
//                   >
//                     <h2
//                       style={{
//                         color: "#eed988",
//                       }}
//                     >
//                       {item?.first_name} {item?.last_name}
//                     </h2>
//                     <h1
//                       style={{
//                         color: "white",
//                       }}
//                     >
//                       {item?.phone}
//                     </h1>
//                   </div>
//                 </div>
//               ))}
//             </div>
//             <h1
//               style={{
//                 color: "#eed988",
//                 display: "flex",
//                 justifyContent: "flex-end",
//               }}
//             >
//               {formData?.phone}
//             </h1>
//           </div>
//         </div>
//       </div>

//       <Button
//         style={{
//           width: "fit-content",
//         }}
//         type="primary"
//         onClick={handleBack}
//       >
//         Back
//       </Button>
//     </div>
//   );
// }
