import { ofType } from "redux-observable";
import { catchError, mergeMap } from "rxjs/operators";
import { from, of } from "rxjs";

import { Api } from "../Helpers/BaseUrlProvider.js";
import { Api1 } from "../Helpers/BaseUrlProvider1.js";

export const AllEpic = (action$) =>
  action$.pipe(
    ofType(
      // auth
      "LOGIN_REQUEST_ATTEMPT",
      "SIGNUP_REQUEST_ATTEMPT",
      "VERIFYOTP_REQUEST_ATTEMPT",
      "RESENTOTP_REQUEST_ATTEMPT",
      "FORGOTPASSWORD_OTP_REQUEST_ATTEMPT",
      "CHANGE_PASSWORD_OTP_REQUEST_ATTEMPT",
      "UPDATE_PASSWORD_REQUEST_ATTEMPT",

      //property-components
      "CATB_REQUEST_ATTEMPT",
      "CATC_REQUEST_ATTEMPT",
      "CATD_REQUEST_ATTEMPT",

      //profile
      "PROFILE_REQUEST_ATTEMPT",
      "PROFILE_UPLOAD_REQUEST_ATTEMPT",
      "CLOGO_UPLOAD_REQUEST_ATTEMPT",
      "UPDATE_PROFILE_DETAIL_REQUEST_ATTEMPT",
      "FETCH_MYACTIVITY_REQUEST_ATTEMPT",
      "SEEN_NOTIFICATION_REQUEST_ATTEMPT",
      "GOOGLE_LOGIN_REQUEST_ATTEMPT",

      // agent
      "FETCH_AGENT_REQUEST_ATTEMPT",
      "SEARCH_AGENT_REQUEST_ATTEMPT",
      "ADD_AGENT_REQUEST_ATTEMPT",
      "EDIT_AGENT_REQUEST_ATTEMPT",
      "DELETE_AGENT_REQUEST_ATTEMPT",
      "FETCH_SEND_AGENT_INVITATION_REQUEST_ATTEMPT",
      "FETCH_CANCEL_AGENT_INVITATION_REQUEST_ATTEMPT",

      //agency
      "FETCH_AGENCY_INQUIRYlIST_REQUEST_ATTEMPT",

      //property
      "POSTAD_CATEGORY_REQUEST_ATTEMPT",
      "SEARCH_CATEGORY_REQUEST_ATTEMPT",
      "AD_PROPERTY_REQUEST_ATTEMPT",
      "FETCH_ALL_PROPERTY_REQUEST_ATTEMPT",
      "REALESTATE_PROFILE_REQUEST_ATTEMPT",
      "FETCH_RECENT_APPROVEDAD_REQUEST_ATTEMPT",
      "ADD_AD_FORM_REQUEST_ATTEMPT",
      "VERIFY_MOBILE_REQUEST_ATTEMPT",
      "VERIFY_MOBILE_OTPCODE_REQUEST_ATTEMPT",
      "UPLOAD_PROPERTY_IMAGE_REQUEST_ATTEMPT",
      "EDIT_UPLOAD_PROPERTY_IMAGE_REQUEST_ATTEMPT",
      "UPLOAD_ROOM_IMAGE_REQUEST_ATTEMPT",
      "FETCH_POST_DELETER_REASON_REQUEST_ATTEMPT",
      "DELETE_POST_REQUEST_ATTEMPT",
      "FETCH_AD_PAYMENT_TYPE_DURATION_REQUEST_ATTEMPT",
      "POST_UPGRADE_PROPERTY_CREDIT_DETAILS_ATTEMPT",
      "FETCH_POST_EDIT_FORM_REQUEST_ATTEMPT",
      "EDIT_AD_FORM_REQUEST_ATTEMPT",
      "FETCH_AD_STATUS_REQUEST_ATTEMPT",
      "FETCH_IMAGE_LIST_REQUEST_ATTEMPT",
      "POST_DEFAULT_REQUEST_ATTEMPT",
      "DELETE_IMAGE_REQUEST_ATTEMPT",
      "UPDATE_AD_STATUS_REQUEST_ATTEMPT",
      "FETCH_ADDRESS_LIST_REQUEST_ATTEMPT",
      "PF_DELETE_IMAGE_REQUEST_ATTEMPT",
      "FETCH_MEDIA_REQUEST_ATTEMPT",

      //coupon
      "COUPON_ADD_REQUEST_ATTEMPT",

      //referral
      "INVITE_LINK_REQUEST_ATTEMPT",
      "FETCH_REFERAL_LOGIN_USER_REQUEST_ATTEMPT",
      "FETCH_CLASSI_CREDIT_REQUEST_ATTEMPT",

      //invite email
      "INVITE_EMAIL_REQUEST_ATTEMPT",

      // todo
      "ADD_TODO_REQUEST_ATTEMPT",
      "FETCH_TODOLIST_REQUEST_ATTEMPT",
      "DELETE_TODOLIST_REQUEST_ATTEMPT",
      "UPDATE_TODOLIST_REQUEST_ATTEMPT",

      //privacy setting
      "GET_PRIVACY_SETTING_REQUEST_ATTEMPT",
      "GET_DEACTIVATE_REASON_REQUEST_ATTEMPT",
      "POST_DEACTIVATE_REASON_REQUEST_ATTEMPT",
      "POST_PRIVACY_SETTING_REQUEST_ATTEMPT",

      //offermessage
      "FETCH_AGENTINQUIRY_LIST_REQUEST_ATTEMPT",
      "FETCH_OFFER_MESSAGE_REQUEST_ATTEMPT",
      "POST_OFFER_MESSAGE_REQUEST_ATTEMPT",
      "FETCH_OFFERMESSAGE_USER_REQUEST_ATTEMPT",
      "FETCH_ACTIVE_USER_CHAT_REQUEST_ATTEMPT",
      "POST_OFFER_FILE_REQUEST_ATTEMPT",

      //find-agent-inquiry
      "FETCH_AGENT_ENQUIRYLIST_REQUEST_ATTEMPT",
      "UPDATE_INQUIRY_STATUS_REQUEST_ATTEMPT",
      "FETCH_AGENT_ENQUIRY_DETAILS_REQUEST_ATTEMPT",
      "SEND_INQUIRY_REQUEST_ATTEMPT",
      "FETCH_INQUIRY_SETTING_REQUEST_ATTEMPT",
      "POST_INQUIRY_SETTING_REQUEST_ATTEMPT",
      "UPLOAD_AGENT_IMAGE_REQUEST_ATTEMPT",
      "FETCH_INQUIRY_CREDIT_DETAILS_ATTEMPT",
      "POST_INQUIRY_CREDIT_DETAILS_ATTEMPT",
      "FETCH_INQUIRY_CREDITLIST_REQUEST_ATTEMPT",

      //payment
      "FETCH_PAYMENT_DATA_REQUEST_ATTEMPT",

      //notification
      "FETCH_NOTIFICATION_REQUEST_ATTEMPT"
    ),
    mergeMap((action) =>
      from(Api(action)).pipe(
        mergeMap((response) => {
          if (action.type_data.successInternalState) {
            action.type_data.successInternalState(response.data);
          }
          return of({
            type: action.type_data.success,
            payload: response.data,
          });
        }),
        catchError((err) => {
          if (action.type_data.failureInternalState) {
            action.type_data.failureInternalState(err?.response?.data);
          }
          return of({ type: action.type_data.failure, payload: err.response });
        })
      )
    )
  );

export const AllEpic1 = (action$) =>
  action$.pipe(
    ofType(),
    //property-components
    // "CATB_REQUEST_ATTEMPT",
    // "CATC_REQUEST_ATTEMPT",
    // "CATD_REQUEST_ATTEMPT"
    mergeMap((action) =>
      from(Api1(action)).pipe(
        mergeMap((response) => {
          if (action.type_data.successInternalState) {
            action.type_data.successInternalState(response.data);
          }
          return of({
            type: action.type_data.success,
            payload: response.data,
          });
        }),
        catchError((err) => {
          if (action.type_data.failureInternalState) {
            action.type_data.failureInternalState(err?.response?.data);
          }
          return of({
            type: action.type_data.failure,
            payload: err.response,
          });
        })
      )
    )
  );
