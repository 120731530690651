import React, { useEffect } from "react";

import DashboardLayout from "../../layout/Layout";
import Breadcrumb from "../../component/breedcrumb/Breadcrumb";
import { Button, DatePicker, Divider, Select, Table } from "antd";
import { useDispatch, connect } from "react-redux";
import { allActions } from "../../Redux/myActions";
import MainLoader from "../../component/loader/MainLoader";
import { timeValueFormatter } from "../../Helpers/FrontendHelper";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import moment from "moment";

const mapStateToProps = (store) => {
  const { PropertyReducer } = store;
  return { PropertyReducer };
};

const InquiryCreditList = ({ PropertyReducer }) => {
  const crumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Inquiry Credit List",
      link: "/find-agent-inquiry/inquiry-credit-list",
    },
  ];

  const dispatch = useDispatch();
  const { loading, inquiryCreditList } = PropertyReducer;

  useEffect(() => {
    fetchInquiryCreditList(1);
  }, []);

  const fetchInquiryCreditList = (pageNo) => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: `inquiry_credit_list?page=${pageNo}`,
          attempt: "FETCH_INQUIRY_CREDITLIST_REQUEST_ATTEMPT",
          success: "FETCH_INQUIRY_CREDITLIST_REQUEST_SUCCESS",
          failure: "FETCH_INQUIRY_CREDITLIST_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  };
  return (
    <DashboardLayout>
      <Breadcrumb title="Inquiry Credit List" crumbList={crumbList} />
      {loading && <MainLoader />}
      <div className="inquiry--credit--list--container">
        {/* <InquirySearchCreditList /> */}
        <MyAdPaymentComponent
          fetchInquiryCreditList={fetchInquiryCreditList}
          inquiryCreditList={inquiryCreditList}
        />
      </div>
    </DashboardLayout>
  );
};

export default connect(mapStateToProps)(InquiryCreditList);

export const InquirySearchCreditList = () => {
  const onChange = (date, dateString) => {};
  return (
    <div className="inquiry--creditlist--container--header">
      {/* <h3>Your Enquiry Credits remaining:</h3> */}
      {/* <Divider /> */}
      <div className="inquiry--header--inner--container">
        <span className="agentinqury--field--wrapper">
          <DatePicker
            className="date--picker--agentinqury"
            size="large"
            placeholder="From Date"
            onChange={onChange}
          />
        </span>
        <span className="agentinqury--field--wrapper">
          <DatePicker
            className="date--picker--agentinqury"
            size="large"
            placeholder="To Date"
            onChange={onChange}
          />
        </span>
        <span className="agentinqury--field--wrapper">
          <Select
            bordered={false}
            className="date--picker--agentinqury"
            size="large"
            placeholder="Payment Method"
            options={[
              {
                label: "All",
                value: "all",
              },
              {
                label: "Stripe",
                value: "stripe",
              },
              {
                label: "Paypal",
                value: "paypal",
              },
              {
                label: "Classi Credit",
                value: "classi-credit",
              },
            ]}
          />
        </span>
        <Button
          shape="round"
          className="button--modal"
          // className="agentinqury--search--button"
          size="large"
        >
          Search
        </Button>
      </div>
    </div>
  );
};

export const MyAdPaymentComponent = ({
  inquiryCreditList,
  fetchInquiryCreditList,
}) => {
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "Id",
      align: "center",
      titleStyle: {
        backgroundColor: "#0b3f77ce !important",
        color: "white !important",
      },
    },
    {
      title: "Credits",
      dataIndex: "credits",
      key: "Credits",
      align: "center",
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "Price",
      align: "center",
    },
    {
      title: "Invoice Status",
      dataIndex: "payment_status",
      key: "Invoice Status",
      align: "center",
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "Payment Method",
      align: "center",
    },
    {
      title: "Date",
      dataIndex: "added_at",
      key: "Date",
      align: "center",
      // render: (date) => timeValueFormatter(date),
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
  ];
  return (
    <div
      style={{ paddingTop: "1rem" }}
      className="inquiry--creditlist--container--body"
    >
      <h3 style={{ padding: "0.5rem 1rem 1rem" }}>My Ads Payment</h3>
      {/* <Divider style={{ margin: "1rem 0" }} /> */}
      <Table
        scroll={{ y: 400, x: 800 }}
        pagination={false}
        bordered
        dataSource={inquiryCreditList?.results}
        columns={columns}
      />
      <div
        style={{
          marginTop: "1rem",
          display: "flex",
          justifyContent: "flex-end",
          gap: "0.5rem",
          alignItems: "center",
        }}
      >
        <Button
          disabled={Number(inquiryCreditList?.current_page) === 1}
          onClick={() =>
            fetchInquiryCreditList(Number(inquiryCreditList?.current_page) - 1)
          }
        >
          <LeftOutlined />
        </Button>
        <Button className="button--modal" type="primary">
          {inquiryCreditList?.current_page}
        </Button>
        <Button
          onClick={() =>
            fetchInquiryCreditList(Number(inquiryCreditList?.current_page) + 1)
          }
          disabled={
            !inquiryCreditList?.has_next_page ||
            inquiryCreditList?.results?.length === 0
          }
        >
          <RightOutlined />
        </Button>
      </div>
    </div>
  );
};
