import { Drawer, Modal } from "antd";
import React, { useEffect, useState } from "react";
import VideoTemplate from "./VideoTemplate";
import VideoTemplateForm from "./VideoTemplateForm";
import { allActions } from "../../../Redux/myActions";
import { useDispatch, useSelector } from "react-redux";

export default function VideoTemplateModal({
  isVideoTemplate,
  setIsVideoTemplate,
  postId,
}) {
  const dispatch = useDispatch();
  const { postEditFormData } = useSelector((store) => store?.PropertyReducer);
  const [activeTemplate, setActiveTemplate] = useState("");
  const [current, setCurrent] = useState(0);
  const [selectedImage, setSelectedImage] = useState([]);

  const [formData, setFormData] = useState({
    postId: "",
    template_name: "",
    description: "",
    hash_tag: [],
    image_list: [],
    music: "",
    video_theme: "",
    caption: "",
  });

  const handleClose = () => {
    setIsVideoTemplate((prev) => !prev);
  };

  useEffect(() => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: `ads_edit_form/${postId}?api_from=classiEstate`,
          attempt: "FETCH_POST_EDIT_FORM_REQUEST_ATTEMPT",
          success: "FETCH_POST_EDIT_FORM_REQUEST_SUCCESS",
          failure: "FETCH_POST_EDIT_FORM_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  }, []);

  useEffect(() => {
    postEditFormData &&
      setFormData((prev) => {
        return {
          ...prev,
          postId: postEditFormData?.post?.post_id,
          template_name: "",
          description: postEditFormData?.post?.description,
          hash_tag: [],
          image_list: [
            ...postEditFormData?.images?.map((img) => img?.image_name),
          ],
        };
      });
  }, [postEditFormData]);

  const componentList = [
    <VideoTemplate
      activeTemplate={activeTemplate}
      setActiveTemplate={setActiveTemplate}
      current={current}
      setCurrent={setCurrent}
    />,
    <VideoTemplateForm
      activeTemplate={activeTemplate}
      setActiveTemplate={setActiveTemplate}
      current={current}
      setCurrent={setCurrent}
      formData={formData}
      setFormData={setFormData}
      selectedImage={selectedImage}
      setSelectedImage={setSelectedImage}
    />,
  ];

  return (
    <Drawer
      title="Generate Video Template Modal"
      open={isVideoTemplate}
      width="1100"
      onClose={handleClose}
      footer={false}
    >
      <div style={{ margin: "0 2rem" }}>{componentList[current]}</div>
    </Drawer>
  );
}
