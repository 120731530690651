import { message } from "antd";
import { axiosApi } from "./apiConfig";
import { getCookie, setCookie } from "./FrontendHelper";

const HeaderData = async (update = false) => {
  const token = getCookie("accessToken");

  if (token === null) {
    return {
      "Content-Type": "application/json",
    };
  } else if (update) {
    return {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + token,
    };
  } else {
    return {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    };
  }
};

export const Api = async (action) => {
  let response;
  switch (action?.type_data?.method?.toLowerCase()) {
    case "get":
      response = await axiosApi.get(action?.type_data?.endPoint, {
        params: { ...action?.type_data?.params },
        headers: await HeaderData(action?.type_data?.multipartFormData),
      });
      break;

    case "post":
      response = await axiosApi.post(
        action?.type_data?.endPoint,
        action?.payload,
        {
          headers: await HeaderData(action?.type_data?.multipartFormData),
        }
      );
      break;

    case "put":
      response = await axiosApi.put(
        action?.type_data?.endPoint,
        action?.payload,
        {
          headers: await HeaderData(action?.type_data?.multipartFormData),
        }
      );
      break;

    case "patch":
      response = await axiosApi.patch(
        action?.type_data?.endPoint,
        action?.payload,
        {
          headers: await HeaderData(action?.type_data?.multipartFormData),
        }
      );
      break;

    case "delete":
      response = await axiosApi.delete(action?.type_data?.endPoint, {
        params: { ...action?.type_data?.params },
        data: { ...action?.type_data?.params },
        headers: await HeaderData(action?.type_data?.multipartFormData),
      });
      break;

    default:
      message.error("Not valid API method");
      break;
  }

  if (action.type_data.saveBearerToken) {
    if (response?.data?.accessToken !== undefined) {
      setCookie("accessToken", response.data.accessToken);
    } else if (response.data.toke !== undefined) {
      setCookie("accessToken", response.data.token);
    } else if (response.data.access_token !== undefined) {
      setCookie("accessToken", response.data.access_token);
    }
  }
  return response;
};



