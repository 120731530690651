import React, { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import {
  Button,
  Table,
  Input,
  Select,
  Segmented,
  Skeleton,
  Tag,
  Tooltip,
  message,
  Image,
} from "antd";
import {
  HomeOutlined,
  DeleteOutlined,
  EditOutlined,
  FileImageOutlined,
  EyeOutlined,
  FormOutlined,
  LeftOutlined,
  RightOutlined,
  SearchOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { CgTemplate } from "react-icons/cg";

import DashboardLayout from "../../layout/Layout";
import Breadcrumb from "../../component/breedcrumb/Breadcrumb";
import { allActions } from "../../Redux/myActions";
import TableSkeleton from "../../skeleton/TableSkeleton";
import { PostImageModal } from "../../component";
import debounce from "lodash.debounce";
import { Link, useNavigate, useParams } from "react-router-dom";
import DeletePostModal from "../../component/modals/DeletePostModal";
import UpgradePostModal from "../../component/modals/UpgradePostModal";
import PostEditModal from "../../component/modals/PostEditModal";
import UpdateAdStatus from "../../component/modals/UpdateAdStatus";
import FlyerTemplateDrawer from "./flyerTemplate/FlyerTemplateDrawer";
import VideoTemplateModal from "./videoTemplate/VideoTemplateModal";
import AdUpgradeDrawer from "./AdUpgradeDrawer/AdUpgradeDrawer";

const link = [
  { propertyStatus: "all" },
  { propertyStatus: "featured" },
  { propertyStatus: "active" },
  { propertyStatus: "inactive" },
];

const crumbList = [
  {
    title: "Dashboard",
    link: "/dashboard",
  },
  {
    title: "All Properties",
    link: "/my-property/all",
  },
];

const mapStateToProps = (store) => {
  const PropertyReducer = store?.PropertyReducer;
  return { PropertyReducer };
};

const Property = ({ PropertyReducer }) => {
  const filter = localStorage.getItem("sortValue");
  const navigate = useNavigate();
  const { allPropertyData, loading } = PropertyReducer;
  const dispatch = useDispatch();
  const [propertyPage, setPropertyPage] = useState(
    allPropertyData?.current_page ? allPropertyData?.current_page : 1
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
  const [openUpgradeAdDrawer, setOpenUpgradeAdDrawer] = useState(false);
  const [modalData, setModalData] = useState({});
  const [sortValue, setSortvalue] = useState("");
  const [postId, setPostId] = useState("");
  const { propertyStatus } = useParams();
  const [isDeletePost, setIsDeletePost] = useState(false);
  const [editOpenModal, setEditOpenModal] = useState(false);
  const [openAdStatus, setOpenAdStatus] = useState(false);
  const [postData, setPostData] = useState("");
  const [isTemplateOpen, setIsTemplateOpen] = useState(false);
  const [isVideoTemplate, setIsVideoTemplate] = useState(false);
  const [propertyType, setPropertyType] = useState("");

  const getEndpoint = (propertyStatus) => {
    let endPoint;
    switch (propertyStatus) {
      case "all":
        endPoint = filter
          ? `my_ads_new?api_from=classiEstate&page=${propertyPage}&order=${filter}`
          : `my_ads_new?api_from=classiEstate&page=${propertyPage}`;
        return endPoint;
      case "featured":
        endPoint = filter
          ? `my_ads_new?api_from=classiEstate&featured=${"1"}&page=${propertyPage}&order=${filter}`
          : `my_ads_new?api_from=classiEstate&featured=${"1"}&page=${propertyPage}`;
        return endPoint;
      case "active":
        endPoint = filter
          ? `my_ads_new?api_from=classiEstate&status=${"Active"}&order=${filter}`
          : `my_ads_new?api_from=classiEstate&status=${"Active"}&page=${propertyPage}`;
        return endPoint;
      case "inactive":
        endPoint = filter
          ? `my_ads_new?api_from=classiEstate&status=${"Inactive"}&order=${filter}`
          : `my_ads_new?api_from=classiEstate&status=${"Inactive"}&page=${propertyPage}`;
        return endPoint;
      default:
        return `my_ads_new?api_from=classiEstate&page=1`;
    }
  };

  useEffect(() => {
    fetchPropertyList();
  }, [propertyPage, propertyStatus, sortValue]);

  const fetchPropertyList = () => {
    const endpoint = getEndpoint(propertyStatus);

    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: endpoint,
          attempt: "FETCH_ALL_PROPERTY_REQUEST_ATTEMPT",
          success: "FETCH_ALL_PROPERTY_REQUEST_SUCCESS",
          failure: "FETCH_ALL_PROPERTY_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  };

  useEffect(() => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "/media_type",
          attempt: "FETCH_MEDIA_REQUEST_ATTEMPT",
          success: "FETCH_MEDIA_REQUEST_SUCCESS",
          failure: "FETCH_MEDIA_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  }, []);

  const handleOpenPostImage = (data) => {
    setModalData(data);
    setPostId(data?.post_id);
    setIsModalOpen(true);
  };

  const handleChangePropertyInput = (e) => {
    const onlyWhitespace = /^\s*$/.test(e.target.value);

    !onlyWhitespace &&
      dispatch(
        allActions(
          {},
          {
            method: "get",
            endPoint: `my_ads?search=${e.target.value}`,
            attempt: "FETCH_ALL_PROPERTY_REQUEST_ATTEMPT",
            success: "FETCH_ALL_PROPERTY_REQUEST_SUCCESS",
            failure: "FETCH_ALL_PROPERTY_REQUEST_FAILURE",

            saveBearerToken: false,
            successInternalState: () => {},
            failureInternalState: () => {},
          }
        )
      );
  };

  const debouncePropertySearch = debounce(handleChangePropertyInput, 1000);

  const handleChangeProperty = (value) => {
    localStorage.setItem("sortValue", value);
    setSortvalue(value);
  };

  const handleChangePropertyType = (value) => {
    localStorage.setItem("sortValue", "");
    navigate(`/my-property/${link[value].propertyStatus}`);
  };

  const columns = [
    {
      title: "Photo",
      align: "center",
      dataIndex: "thumbnail",
      width: 180,
      render: (thumbnail) => {
        return thumbnail ? (
          <Image
            src={thumbnail}
            alt=""
            style={{
              width: "100%",
              height: "auto",
              objectFit: "cover",
              border: "1px solid gray",
              background: "white",
              maxHeight: "7rem",
            }}
          />
        ) : (
          <HomeOutlined
            style={{
              fontSize: "3rem",
              background: "#808080",
              color: "white",
              padding: "0.5rem",
            }}
          />
        );
      },
    },
    {
      title: "Property Type",
      align: "center",
      dataIndex: "property_type",
      render: (type) => <p className="capitalize-first-letter">{type}</p>,
    },
    {
      title: "Title",
      align: "center",
      width: 200,
      dataIndex: "title",
      render: (title) => <p className="capitalize-first-letter">{title}</p>,
    },
    {
      title: "Category",
      align: "center",
      dataIndex: "catname",
      render: (catname) => <p className="capitalize-first-letter">{catname}</p>,
    },
    {
      title: "Type",
      align: "center",
      dataIndex: "offer_type",
    },

    {
      title: "Ad Status",
      align: "center",
      render: (_, data) => {
        return (
          <Tag
            onClick={() => handleSetPostData(data)}
            style={{ cursor: "pointer" }}
          >
            {data?.statusname}
          </Tag>
        );
      },
    },
    {
      title: "Price",
      align: "center",
      render: (data) => {
        return (
          data?.price !== "0" && (
            <div>
              $ {data?.price}{" "}
              {data?.to_price !== "0" && data?.price !== data?.to_price && (
                <span> - ${data?.to_price} </span>
              )}{" "}
            </div>
          )
        );
      },
    },
    {
      title: "Location",
      align: "center",
      dataIndex: "ad_address",
      width: "12%",
      render: (address) => {
        return <p>{address}</p>;
      },
    },
    {
      title: "Date",
      align: "center",
      dataIndex: "posted_date",
      render: (posted_date) => {
        return <p>{posted_date ? posted_date : "NaN"}</p>;
      },
    },
    {
      title: "Enquiry",
      align: "center",
      render: (data) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                height: "1.5rem",
                width: "1.5rem",
                borderRadius: "3px",
                background: "gray",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => {
                Number(data?.enquiry_number) > 0
                  ? handleNavigateOfferPage(data)
                  : message.warning("No Post Offer Message");
              }}
            >
              {data?.enquiry_number}
            </span>
          </div>
        );
      },
    },
    {
      title: "Action",
      align: "center",
      width: "12%",
      dataIndex: "action",
      fixed: "right",
      render: (_, data) => {
        return (
          <div className="property--action--container">
            <Tooltip title="Post Image" color="gray">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <FileImageOutlined
                  onClick={() => handleOpenPostImage(data)}
                  className="action--icon"
                />
              </div>
            </Tooltip>
            <Link
              to={
                data?.catname === "Commercial"
                  ? `https://commercial.classiestate.com.au/property-detail?property_category=${data?.property_type}&indx=${data?.post_id}&key=${data?.title}`
                  : `https://classiestate.com.au/property-detail?property_category=${data?.property_type}&indx=${data?.post_id}&key=${data?.title}`
              }
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <Tooltip title="Post Detail" color="gray">
                  <EyeOutlined className="action--icon" />
                </Tooltip>
              </div>
            </Link>
            {/* <Tooltip title="Upgrade Post" color="gray">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <FormOutlined
                  onClick={() => {
                    setPostId(data?.post_id);
                    setOpenUpgradeModal(true);
                  }}
                  className="action--icon"
                />
              </div>
            </Tooltip>{" "} */}
            <Tooltip title="Upgrade Post" color="gray">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <FormOutlined
                  onClick={() => {
                    setPostId(data?.post_id);
                    setOpenUpgradeAdDrawer(true);
                  }}
                  className="action--icon"
                />
              </div>
            </Tooltip>{" "}
            <Tooltip title="Edit Post" color="gray">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <EditOutlined
                  onClick={() => {
                    setPostId(data?.post_id);
                    setEditOpenModal(true);
                  }}
                  className="action--icon"
                />
              </div>
            </Tooltip>
            <Tooltip title="Generate Flyer Template" color="gray">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <CgTemplate
                  onClick={() => {
                    setPostId(data?.post_id);
                    setIsTemplateOpen(true);
                  }}
                  className="action--icon"
                  style={{ fontSize: "1.5rem" }}
                />
              </div>
            </Tooltip>
            <Tooltip title="Generate Video Templete" color="gray">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <VideoCameraOutlined
                  onClick={() => {
                    setPostId(data?.post_id);
                    setIsVideoTemplate(true);
                  }}
                  className="action--icon"
                  style={{ fontSize: "1.1rem" }}
                />
              </div>
            </Tooltip>
            <Tooltip title="Delete Post" color="gray">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "center",
                }}
              >
                <DeleteOutlined
                  onClick={() => {
                    setPostId(data?.post_id);
                    setIsDeletePost(true);
                  }}
                  className="action--icon1"
                />
              </div>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const handleSetPostData = (data) => {
    setPostData(data);
    setPropertyType(data?.property_type);
    setOpenAdStatus((prev) => !prev);
  };

  const handleNavigateOfferPage = (data) => {
    navigate("/offer-message/offer-received", { state: data?.post_id });
  };

  if (loading) {
    return (
      <DashboardLayout>
        <Breadcrumb title="My Properties" crumbList={crumbList} />
        <div className="property--list--container">
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              gap: "0.2rem",
              justifyContent: "flex-start",
              margin: "0",
              padding: "0",
            }}
          >
            <Skeleton.Button
              style={{ width: "9rem", height: "1.8rem" }}
              active={true}
            />
            <Skeleton.Button
              style={{ width: "6.6rem", height: "1.8rem" }}
              active={true}
            />
            <Skeleton.Button
              style={{ width: "5.8rem", height: "1.8rem" }}
              active={true}
            />
            <Skeleton.Button
              style={{ width: "5.8rem", height: "1.8rem" }}
              active={true}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              margin: "2rem 0 1rem",
            }}
          >
            <Skeleton.Input
              style={{
                borderRadius: "0.5rem",
                height: "2.5rem",
                width: "35rem",
              }}
            />

            <Skeleton.Button style={{ width: "8rem" }} />
          </div>
          <TableSkeleton />
        </div>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <Breadcrumb title="My Properties" crumbList={crumbList} />
      <Segmented
        className="property--segment"
        defaultValue={propertyStatus}
        options={[
          {
            label: (
              <span onClick={() => handleChangePropertyType(0)}>
                All Properties [{allPropertyData?.all_ads}]
              </span>
            ),
            value: "all",
          },
          {
            label: (
              <span onClick={() => handleChangePropertyType(1)}>
                Featured [{allPropertyData?.featured_ads}]
              </span>
            ),
            value: "featured",
          },
          {
            label: (
              <span onClick={() => handleChangePropertyType(2)}>
                Active [{allPropertyData?.active_ads}]
              </span>
            ),
            value: "active",
          },
          {
            label: (
              <span onClick={() => handleChangePropertyType(3)}>
                Inactive [{allPropertyData?.inactive_ads}]
              </span>
            ),
            value: "inactive",
          },
        ]}
      />
      <div
        style={{ margin: "2rem 0 1rem" }}
        className="agent--container--header"
      >
        <Input
          prefix={
            <SearchOutlined
              style={{
                color: "gray",
                fontSize: "1.5rem",
                marginRight: "1rem",
              }}
            />
          }
          onChange={debouncePropertySearch}
          placeholder="Search Here"
          size="large"
          className="search--agent--input"
        />
        <div style={{ display: "flex", flexDirection: "row", gap: "0.5rem" }}>
          <Select
            defaultValue={filter ? filter : null}
            onChange={handleChangeProperty}
            placeholder="Sort by"
            style={{
              width: "8rem",
            }}
            options={[
              {
                value: "Most Recent",
                label: "Most Recent",
              },
              {
                value: "Most Oldest",
                label: "Most Oldest",
              },
              {
                value: "Lowest Price",
                label: "Lowest Price",
              },
              {
                value: "Highest Price",
                label: "Highest Price",
              },
            ]}
          />
        </div>
      </div>
      <div className="property--list--container">
        {allPropertyData?.posts && (
          <Table
            bordered
            scroll={{ y: 450, x: 1400 }}
            pagination={false}
            columns={columns}
            size="small"
            dataSource={[...allPropertyData?.posts]}
          />
        )}
        <div
          style={{
            marginTop: "1rem",
            display: "flex",
            justifyContent: "flex-end",
            gap: "0.5rem",
            alignItems: "center",
          }}
        >
          <Button
            disabled={propertyPage == 1}
            onClick={() => setPropertyPage((prev) => Number(prev) - 1)}
          >
            <LeftOutlined />
          </Button>
          <Button className="button--modal--primary" type="primary">
            {propertyPage}
          </Button>
          <Button
            onClick={() => setPropertyPage((prev) => Number(prev) + 1)}
            disabled={
              !allPropertyData?.has_next_page ||
              allPropertyData?.posts.length === 0
            }
          >
            <RightOutlined />
          </Button>
        </div>
      </div>
      {isModalOpen && (
        <PostImageModal
          postId={postId}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          modalData={modalData}
          fetchPropertyList={fetchPropertyList}
        />
      )}
      {/* {postId && (
        <UpgradePostModal
          postId={postId}
          setPostId={setPostId}
          setOpenUpgradeModal={setOpenUpgradeModal}
          openUpgradeModal={openUpgradeModal}
        />
      )} */}
      {postId && (
        <DeletePostModal
          postId={postId}
          setPostId={setPostId}
          isDeletePost={isDeletePost}
          setIsDeletePost={setIsDeletePost}
          fetchPropertyList={fetchPropertyList}
        />
      )}
      {postId && editOpenModal && (
        <PostEditModal
          fetchPropertyList={fetchPropertyList}
          setEditOpenModal={setEditOpenModal}
          editOpenModal={editOpenModal}
          postId={postId}
        />
      )}
      {postData && (
        <UpdateAdStatus
          fetchPropertyList={fetchPropertyList}
          setPostData={setPostData}
          openAdStatus={openAdStatus}
          setOpenAdStatus={setOpenAdStatus}
          postData={postData}
          propertyType={propertyType}
        />
      )}

      {/* flyer section */}

      {isTemplateOpen && (
        <FlyerTemplateDrawer
          postId={postId}
          setIsTemplateOpen={setIsTemplateOpen}
          isTemplateOpen={isTemplateOpen}
        />
      )}

      {isVideoTemplate && (
        <VideoTemplateModal
          isVideoTemplate={isVideoTemplate}
          setIsVideoTemplate={setIsVideoTemplate}
          postId={postId}
        />
      )}

      {openUpgradeAdDrawer && (
        <AdUpgradeDrawer
          openUpgradeAdDrawer={openUpgradeAdDrawer}
          setOpenUpgradeAdDrawer={setOpenUpgradeAdDrawer}
          postId={postId}
        />
      )}
    </DashboardLayout>
  );
};

export default connect(mapStateToProps)(Property);
