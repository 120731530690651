import { message } from "antd";
import { async } from "rxjs";

const INIT_STATE = {
  loading: false,
  selectcatloading: false,
  approvedadloading: false,
  roomloading: false,
  addeleteloading: false,
  deletepostloading: false,
  adupgradeloading: false,
  editformloading: false,
  adloading: false,
  imageloading: false,
  updateadloading: false,
  seennotificationloading: false,
  editimageloading: false,
  addressloading: false,
  dimageloading: false,
  enquiryAd: {},
  catA: {},
  catB: [],
  catC: [],
  catD: [],
  postAdForm: {},
  allPropertyData: {},
  realEstateData: "",
  recentApprovedAd: [],
  propertyImages: [],
  propertyRoomImages: [],
  paymentList: [],
  adDeleteReasonList: [],
  adPaymentTypeList: [],
  adPaymentDurationList: {},
  adDurationAndPaymentType: {},
  postEditFormData: "",
  inquiryCreditList: {},
  adImageList: [],
  mediaType: "",
};

export const PropertyReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    //reset state
    case "RESET_PROPERTY_STATE": {
      return {
        ...state,
        loading: false,
      };
    }

    case "CLEAR_POSTEDIT_FORMDATA": {
      return {
        ...state,
        postEditFormData: "",
      };
    }

    //ad form
    case "ADD_AD_FORM_REQUEST_ATTEMPT": {
      return {
        ...state,
        adloading: true,
      };
    }

    case "ADD_AD_FORM_REQUEST_SUCCESS": {
      // message.success(action?.payload?.data?.message);
      message.success("Property Added Successfully");
      return {
        ...state,
        adloading: false,
      };
    }

    case "ADD_AD_FORM_REQUEST_FAILURE": {
      return {
        ...state,
        adloading: false,
      };
    }

    //profile--section
    case "POSTAD_CATEGORY_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "POSTAD_CATEGORY_REQUEST_SUCCESS": {
      return {
        ...state,
        loading: false,
        enquiryAd: action?.payload,
      };
    }
    case "POSTAD_CATEGORY_REQUEST_FAILURE": {
      return {
        ...state,
        loading: false,
      };
    }

    //property
    case "SEARCH_CATEGORY_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "SEARCH_CATEGORY_REQUEST_SUCCESS": {
      const value = action?.payload?.filter(
        (item) => item?.cat_name === "Real Estate"
      )[0];
      localStorage.setItem("cat1", value?.cat_id);
      return {
        ...state,
        loading: false,
        catA: value,
      };
    }
    case "SEARCH_CATEGORY_REQUEST_FAILURE": {
      return {
        ...state,
        loading: false,
      };
    }

    //catb
    case "CATB_REQUEST_ATTEMPT": {
      return {
        ...state,
        selectcatloading: true,
      };
    }

    case "CATB_REQUEST_SUCCESS": {
      return {
        ...state,
        selectcatloading: false,
        catB: action?.payload,
      };
    }
    case "CATB_REQUEST_FAILURE": {
      message.error(action?.payload?.status === 500 && "Internal server error");
      return {
        ...state,
        selectcatloading: false,
      };
    }

    //catc
    case "CATC_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "CATC_REQUEST_SUCCESS": {
      return {
        ...state,
        loading: false,
        catC: action?.payload,
      };
    }
    case "CATC_REQUEST_FAILURE": {
      return {
        ...state,
        loading: false,
      };
    }

    //catd
    case "CATD_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "CATD_REQUEST_SUCCESS": {
      return {
        ...state,
        loading: false,
        catD: action?.payload,
      };
    }
    case "CATD_REQUEST_FAILURE": {
      return {
        ...state,
        loading: false,
      };
    }

    //ad--get--property
    case "AD_PROPERTY_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "AD_PROPERTY_REQUEST_SUCCESS": {
      return {
        ...state,
        loading: false,
        postAdForm: action?.payload?.fields,
      };
    }
    case "AD_PROPERTY_REQUEST_FAILURE": {
      return {
        ...state,
        loading: false,
      };
    }

    //fetch all properties
    case "FETCH_ALL_PROPERTY_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "FETCH_ALL_PROPERTY_REQUEST_SUCCESS": {
      return {
        ...state,
        loading: false,
        allPropertyData: action?.payload,
      };
    }

    case "FETCH_ALL_PROPERTY_REQUEST_FAILURE": {
      return {
        ...state,
        loading: false,
      };
    }

    case "CLEAR_PROPERTY_DATA": {
      return {
        ...state,
        postEditFormData: "",
        editformloading: false,
      };
    }

    case "FETCH_MEDIA_REQUEST_ATTEMPT": {
      return {
        ...state,
      };
    }

    case "FETCH_MEDIA_REQUEST_SUCCESS": {
      return {
        ...state,
        mediaType: action?.payload,
      };
    }
    case "FETCH_MEDIA_REQUEST_FAILURE": {
      return {
        ...state,
      };
    }

    //realestate Profile
    case "REALESTATE_PROFILE_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }
    case "REALESTATE_PROFILE_REQUEST_SUCCESS": {
      localStorage.setItem("realestatedata", JSON.stringify(action?.payload));
      return {
        ...state,
        loading: false,
        realEstateData: action?.payload,
      };
    }
    case "REALESTATE_PROFILE_REQUEST_FAILURE": {
      return {
        ...state,
        loading: false,
      };
    }

    //recent approved ad
    case "FETCH_RECENT_APPROVEDAD_REQUEST_ATTEMPT": {
      return {
        ...state,
        approvedadloading: true,
      };
    }

    case "FETCH_RECENT_APPROVEDAD_REQUEST_SUCCESS": {
      return {
        ...state,
        approvedadloading: false,
        recentApprovedAd: action?.payload,
      };
    }
    case "FETCH_RECENT_APPROVEDAD_REQUEST_FAILURE": {
      return {
        ...state,
        approvedadloading: false,
      };
    }

    // verify mobile number
    case "VERIFY_MOBILE_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "VERIFY_MOBILE_REQUEST_SUCCESS": {
      message.success(action?.payload?.message);
      return {
        ...state,
        loading: false,
      };
    }
    case "VERIFY_MOBILE_REQUEST_FAILURE": {
      return {
        ...state,
        loading: false,
      };
    }

    // verfiy mobile otp code
    case "VERIFY_MOBILE_OTPCODE_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "VERIFY_MOBILE_OTPCODE_REQUEST_SUCCESS": {
      message?.success(action?.payload?.message);
      return {
        ...state,
        loading: false,
      };
    }
    case "VERIFY_MOBILE_OTPCODE_REQUEST_FAILURE": {
      message?.error(action?.payload?.data?.errors);
      return {
        ...state,
        loading: false,
      };
    }

    // property image
    case "UPLOAD_PROPERTY_IMAGE_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "UPLOAD_PROPERTY_IMAGE_REQUEST_SUCCESS": {
      return {
        ...state,
        loading: false,
        propertyImages: action?.payload?.source,
      };
    }
    case "UPLOAD_PROPERTY_IMAGE_REQUEST_FAILURE": {
      return {
        ...state,
        loading: false,
      };
    }

    case "EDIT_UPLOAD_PROPERTY_IMAGE_REQUEST_ATTEMPT": {
      return {
        ...state,
        editimageloading: true,
      };
    }

    case "EDIT_UPLOAD_PROPERTY_IMAGE_REQUEST_SUCCESS": {
      message.success(action?.payload?.message);
      return {
        ...state,
        editimageloading: false,
        propertyImages: action?.payload?.source,
      };
    }
    case "EDIT_UPLOAD_PROPERTY_IMAGE_REQUEST_FAILURE": {
      return {
        ...state,
        editimageloading: false,
      };
    }

    case "UPLOAD_ROOM_IMAGE_REQUEST_ATTEMPT": {
      return {
        ...state,
        roomloading: true,
      };
    }

    case "UPLOAD__ROOM_IMAGE_REQUEST_SUCCESS": {
      return {
        ...state,
        roomloading: false,
        propertyRoomImages: action?.payload?.source,
      };
    }

    case "UPLOAD__ROOM_IMAGE_REQUEST_FAILURE": {
      return {
        ...state,
        roomloading: false,
      };
    }

    case "FETCH_PAYMENT_DATA_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "FETCH_PAYMENT_DATA_REQUEST_SUCCESS": {
      return {
        ...state,
        loading: false,
        paymentList: action?.payload,
      };
    }

    case "FETCH_PAYMENT_DATA_REQUEST_FAILURE": {
      return {
        ...state,
        loading: false,
      };
    }

    //post delete reason
    case "FETCH_POST_DELETER_REASON_REQUEST_ATTEMPT": {
      return {
        addeleteloading: true,
        ...state,
      };
    }

    case "FETCH_POST_DELETER_REASON_REQUEST_SUCCESS": {
      return {
        addeleteloading: false,
        ...state,
        adDeleteReasonList: action?.payload,
      };
    }
    case "FETCH_POST_DELETER_REASON_REQUEST_FAILURE": {
      return {
        addeleteloading: false,
        ...state,
      };
    }

    //detet ad
    case "DELETE_POST_REQUEST_ATTEMPT": {
      return {
        deletepostloading: true,
        ...state,
      };
    }

    case "DELETE_POST_REQUEST_SUCCESS": {
      message.success(action?.payload?.message);
      return {
        deletepostloading: false,
        ...state,
      };
    }

    case "DELETE_POST_REQUEST_FAILURE": {
      return {
        deletepostloading: false,
        ...state,
      };
    }

    //fetch paymenttype & duration
    case "FETCH_AD_PAYMENT_TYPE_DURATION_REQUEST_ATTEMPT": {
      return {
        ...state,
        adupgradeloading: true,
      };
    }

    case "FETCH_AD_PAYMENT_TYPE_DURATION_REQUEST_SUCCESS": {
      return {
        ...state,
        adupgradeloading: false,
        adDurationAndPaymentType: action?.payload,
      };
    }
    case "FETCH_AD_PAYMENT_TYPE_DURATION_REQUEST_FAILURE": {
      return {
        ...state,
        adupgradeloading: false,
      };
    }

    //upgrade post
    case "POST_UPGRADE_PROPERTY_CREDIT_DETAILS_ATTEMPT": {
      return {
        ...state,
        adupgradeloading: true,
      };
    }

    case "POST_UPGRADE_PROPERTY_CREDIT_DETAILS_SUCCESS": {
      message.success(action?.payload?.data?.message);
      return {
        ...state,
        adupgradeloading: false,
      };
    }

    case "POST_UPGRADE_PROPERTY_CREDIT_DETAILS_FAILURE": {
      message.error(action?.payload?.data?.errors);
      return {
        ...state,
        adupgradeloading: false,
      };
    }

    //fetch form data
    case "FETCH_POST_EDIT_FORM_REQUEST_ATTEMPT": {
      return {
        ...state,
        editformloading: true,
        propertyImages: [],
        propertyRoomImages: [],
      };
    }

    case "FETCH_POST_EDIT_FORM_REQUEST_SUCCESS": {
      localStorage.setItem(
        "ad_status",
        JSON.stringify(
          action?.payload?.form_fields?.data?.static_fields?.find(
            (item) => item?.attr?.name === "ad_status"
          )
        )
      );
      return {
        ...state,
        editformloading: false,
        postEditFormData: action?.payload?.data,
      };
    }
    case "FETCH_POST_EDIT_FORM_REQUEST_FAILURE": {
      return {
        ...state,
        editformloading: false,
      };
    }

    //fetch ad status
    case "FETCH_AD_STATUS_REQUEST_ATTEMPT": {
      return {
        ...state,
      };
    }

    case "FETCH_AD_STATUS_REQUEST_SUCCESS": {
      (async () => {
        try {
          localStorage.setItem(
            "ad_status",
            JSON.stringify(
              action?.payload?.data?.form_fields?.static_fields?.find(
                (item) => item?.attr?.name === "ad_status"
              )
            )
          );
        } catch (error) {}
      })();
      return {
        ...state,
      };
    }
    case "FETCH_AD_STATUS_REQUEST_FAILURE": {
      return {
        ...state,
      };
    }

    //delete property
    case "EDIT_AD_FORM_REQUEST_ATTEMPT": {
      return {
        ...state,
        postadloading: true,
      };
    }

    case "EDIT_AD_FORM_REQUEST_SUCCESS": {
      message.success(action?.payload?.data?.message);
      return {
        ...state,
        postadloading: false,
      };
    }

    case "EDIT_AD_FORM_REQUEST_FAILURE": {
      message?.error(action?.payload?.errors);
      return {
        ...state,
        postadloading: false,
      };
    }

    case "FETCH_INQUIRY_CREDITLIST_REQUEST_ATTEMPT": {
      return {
        ...state,
        loading: true,
      };
    }

    case "FETCH_INQUIRY_CREDITLIST_REQUEST_SUCCESS": {
      // let data = action?.payload;
      // const result =
      //   action?.payload?.results?.length > 0
      //     ? action?.payload?.results?.reverse()
      //     : [];
      // data.results = result;
      return {
        // inquiryCreditList: data,
        ...state,
        inquiryCreditList: action?.payload,
        loading: false,
      };
    }

    case "FETCH_INQUIRY_CREDITLIST_REQUEST_FAILURE": {
      return {
        ...state,
        loading: false,
      };
    }

    //ads image list
    case "FETCH_IMAGE_LIST_REQUEST_ATTEMPT": {
      return {
        ...state,
        imageLoading: true,
      };
    }

    case "FETCH_IMAGE_LIST_REQUEST_SUCCESS": {
      const AdImage =
        action?.payload?.length > 0
          ? action?.payload?.filter(
              (item) => item?.image_type?.toLowerCase() === "image"
            )
          : [];

      return {
        ...state,
        imageloading: false,
        // adImageList: action?.payload,
        adImageList: AdImage,
      };
    }

    case "FETCH_IMAGE_LIST_REQUEST_FAILURE": {
      return {
        ...state,
        imageloading: false,
      };
    }

    //make default image
    case "POST_DEFAULT_REQUEST_ATTEMPT": {
      return {
        ...state,
        imageloading: true,
      };
    }
    case "POST_DEFAULT_REQUEST_SUCCESS": {
      message.success(action?.payload);
      return {
        ...state,
        imageloading: false,
      };
    }
    case "POST_DEFAULT_REQUEST_FAILURE": {
      return {
        ...state,
        imageloading: false,
      };
    }

    case "DELETE_IMAGE_REQUEST_ATTEMPT": {
      return {
        ...state,
        imageloading: true,
      };
    }
    case "DELETE_IMAGE_REQUEST_SUCCESS": {
      message.success("Property Image deleted Successfully");
      return {
        ...state,
        imageloading: false,
      };
    }
    case "DELETE_IMAGE_REQUEST_FAILURE": {
      return {
        ...state,
        imageloading: false,
      };
    }

    case "CLEAR_OFFER_MESSAGE": {
      return {};
    }

    case "UPDATE_AD_STATUS_REQUEST_ATTEMPT": {
      return {
        ...state,
        updateadloading: true,
      };
    }
    case "UPDATE_AD_STATUS_REQUEST_SUCCESS": {
      message.success("Property Status Change Successfully");

      return {
        ...state,
        updateadloading: false,
      };
    }

    case "UPDATE_AD_STATUS_REQUEST_FAILURE": {
      return {
        ...state,
        updateadloading: false,
      };
    }

    //fetch address
    case "FETCH_ADDRESS_LIST_REQUEST_ATTEMPT": {
      return {
        ...state,
        addressloading: true,
      };
    }

    case "FETCH_ADDRESS_LIST_REQUEST_SUCCESS": {
      return {
        ...state,
        addressloading: false,
      };
    }

    case "FETCH_ADDRESS_LIST_REQUEST_FAILURE": {
      return {
        ...state,
        addressloading: false,
      };
    }

    case "PF_DELETE_IMAGE_REQUEST_ATTEMPT": {
      return {
        ...state,
        dimageloading: true,
      };
    }

    case "PF_DELETE_IMAGE_REQUEST_SUCCESS": {
      return {
        ...state,
        dimageloading: false,
      };
    }

    case "PF_DELETE_IMAGE_REQUEST_FAILURE": {
      return {
        ...state,
        dimageloading: false,
      };
    }

    default:
      return state;
  }
};
