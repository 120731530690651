import React from "react";
import DashboardLayout from "../../layout/Layout";
import Breadcrumb from "../../component/breedcrumb/Breadcrumb";
import { Divider } from "antd";

export default function IntegrationPage() {
  const crumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Integration",
      link: "/integration",
    },
  ];

  return (
    <DashboardLayout>
      <Breadcrumb title="Integration" crumbList={crumbList} />
      <div
        style={{
          margin: "1.5rem 0",
          display: "grid",
          gridTemplateColumns: "1fr 4fr",
          gap: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            color: "slategrey",
            fontSize: "1rem",
            cursor: "pointer",
          }}
        >
          {/* <span>Eagle CRM</span>
          <Divider style={{ margin: 0 }} />
          <span>RE CRM</span>
          <Divider style={{ margin: 0 }} />
          <span>REX Software</span>
          <Divider style={{ margin: 0 }} />
          <span>Agentbox</span>
          <Divider style={{ margin: 0 }} /> */}
          <span> Classiestate.com.au</span>
          <Divider style={{ margin: 0 }} />
          <span>Classibazaar.com.au,</span>
          <Divider style={{ margin: 0 }} />
          <span>rent.com.au</span>
          <Divider style={{ margin: 0 }} />
          <span>domain.com.au</span>
          <Divider style={{ margin: 0 }} />
          <span>homely.com.au</span>
          <Divider style={{ margin: 0 }} />
        </div>
      </div>
    </DashboardLayout>
  );
}
