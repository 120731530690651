import React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  DatePicker,
  Divider,
  Drawer,
  Form,
  Image,
  Select,
  Table,
  Tooltip,
  message,
} from "antd";
import { useDispatch, connect } from "react-redux";
import { EyeOutlined } from "@ant-design/icons";

import DashboardLayout from "../../layout/Layout";
import Breadcrumb from "../../component/breedcrumb/Breadcrumb";
import { allActions } from "../../Redux/myActions";
import MainLoader from "../../component/loader/MainLoader";
import { formatDateFunction } from "../../Helpers/FrontendHelper";
import moment from "moment";

const mapStateToProp = (store) => {
  const { PropertyReducer } = store;

  return { PropertyReducer };
};

const Payment = ({ PropertyReducer }) => {
  const dispatch = useDispatch();
  const { paymentList, loading } = PropertyReducer;

  useEffect(() => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: "payment_history",
          attempt: "FETCH_PAYMENT_DATA_REQUEST_ATTEMPT",
          success: "FETCH_PAYMENT_DATA_REQUEST_SUCCESS",
          failure: "FETCH_PAYMENT_DATA_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  }, []);
  const crumbList = [
    {
      title: "Dashboard",
      link: "/dashboard",
    },
    {
      title: "Payment",
      link: "/payment",
    },
  ];
  return (
    <DashboardLayout>
      <Breadcrumb title="Payment" crumbList={crumbList} />
      {loading && <MainLoader />}
      <div className="payment--container">
        <PaymentSearchComponent />
        {paymentList && <PaymentListComponent paymentList={paymentList} />}
      </div>{" "}
    </DashboardLayout>
  );
};

export default connect(mapStateToProp)(Payment);

export const PaymentSearchComponent = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleSearchPayment = (values) => {
    if (values?.from_date || values?.to_date || values?.payment_method) {
      const fromDate = formatDateFunction(values?.from_date);
      const toDate = formatDateFunction(values?.to_date);

      dispatch(
        allActions(
          {},
          {
            method: "get",
            endPoint: `payment_history?${
              fromDate ? `from_date=${fromDate}&` : ""
            }${toDate ? `to_date=${toDate}&` : ""}${
              values?.payment_method
                ? `payment_method=${values?.payment_method}`
                : ""
            }`,
            attempt: "FETCH_PAYMENT_DATA_REQUEST_ATTEMPT",
            success: "FETCH_PAYMENT_DATA_REQUEST_SUCCESS",
            failure: "FETCH_PAYMENT_DATA_REQUEST_FAILURE",

            saveBearerToken: false,
            successInternalState: () => {
              form.resetFields();
            },
            failureInternalState: () => {},
          }
        )
      );
    } else {
      message.warning("Search Field shouldn't be Empty");
    }
  };

  return (
    <div className="payment--container--header">
      <Form
        form={form}
        onFinish={handleSearchPayment}
        className="payment--header--inner--container"
      >
        <Form.Item
          style={{ margin: "0" }}
          name="from_date"
          className="payment--field--wrapper"
        >
          <DatePicker
            className="date--picker--payment"
            size="large"
            placeholder="From Date"
            format="DD-MM-YYYY"
          />
        </Form.Item>
        <Form.Item
          style={{ margin: "0" }}
          name="to_date"
          className="payment--field--wrapper"
        >
          <DatePicker
            className="date--picker--payment"
            size="large"
            placeholder="To Date"
            format="DD-MM-YYYY"
          />
        </Form.Item>
        <Form.Item
          style={{ margin: "0" }}
          name="payment_method"
          className="payment--field--wrapper"
        >
          <Select
            bordered={false}
            className="date--picker--payment"
            size="large"
            placeholder="Payment Method"
            options={[
              {
                label: "Stripe",
                value: "Stripe",
              },
              {
                label: "Classi Credit",
                value: "Classi Credit",
              },
            ]}
          />
        </Form.Item>
        <Button
          htmlType="submit"
          shape="round"
          className="button--modal"
          style={{
            borderColor: "white",
            borderRadius: "0.5rem 2rem 2rem 0.5rem ",
          }}
          size="large"
        >
          Search
        </Button>
      </Form>
    </div>
  );
};

export const PaymentListComponent = ({ paymentList }) => {
  const handlePDFLinkClick = (event, payment_pdf) => {
    if (payment_pdf?.length > 0) {
      event.preventDefault();
      window.open(payment_pdf, "_blank");
    } else {
      message.warning("No payment Slip");
    }
  };

  const columns = [
    {
      title: "Photo",
      align: "center",
      dataIndex: "image",
      width: 180,
      key: "image",
      render: (image) => {
        return (
          <Image
            style={{
              width: "100%",
              height: "auto",
              border: "1px solid  #F5F5F5",
              background: "white",
              objectFit: "cover",
              maxHeight: "6rem",
            }}
            src={image}
            alt=""
          />
        );
      },
    },
    {
      title: "Title",
      align: "center",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Type",
      align: "center",
      key: "Type",
      render: (_, data) => {
        return (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gap: "0.2rem",
            }}
          >
            <span>Featured</span>
            <span>{data?.featured === "1" ? "Yes" : "No"}</span>

            <span style={{ display: "flex", justifyContent: "flex-start" }}>
              Urgent
            </span>
            <span>{data?.urgent === "1" ? "Yes" : "No"}</span>

            <span>Spotlight</span>
            <span>{data?.spotlight === "1" ? "Yes" : "No"}</span>
          </div>
        );
      },
    },
    {
      title: "Amount",
      align: "center",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Classi Credit Rate",
      align: "center",
      dataIndex: "classi_credit_rate",
      key: "classi_credit_rate",
      render: (clasi_credit_rate) => (
        <span>{clasi_credit_rate ? clasi_credit_rate : "-"}</span>
      ),
    },
    {
      title: "Invoice Status",
      align: "center",
      dataIndex: "payment_status",
      key: "payment_status",
    },
    {
      title: "Payment Method",
      align: "center",
      dataIndex: "payment_method",
      key: "payment_method ",
    },
    {
      title: "Date",
      align: "center",
      key: "payment_date",
      dataIndex: "payment_date",
      render: (date) => <span>{moment(date)?.format("DD-MM-YYYY")}</span>,
    },
    {
      title: "Action",
      align: "center",
      dataIndex: "payment_pdf",
      key: "payment_pdf",
      render: (payment_pdf) => {
        return (
          <Tooltip placement="top" title="Download Payment Pdf">
            <EyeOutlined
              style={{ fontSize: "1.2rem" }}
              onClick={(e) => handlePDFLinkClick(e, payment_pdf)}
            />
          </Tooltip>
        );
      },
    },
  ];
  return (
    <div className="payment--container--body">
      <Table
        bordered
        scroll={{ y: 400, x: 800 }}
        pagination={false}
        dataSource={[...paymentList]}
        columns={columns}
      />
    </div>
  );
};
