import { useEffect, useState } from "react";
import {
  Divider,
  Form,
  Modal,
  Tag,
  Checkbox,
  Select,
  DatePicker,
  Button,
  InputNumber,
  Radio,
  Input,
  message,
} from "antd";
import { useDispatch, connect } from "react-redux";
import CurrentScreenSize from "../screenSizeReader/CurrentScreenSize";
import mastercard from "../../assets/matercard.jpg";

import { allActions } from "../../Redux/myActions";
import StripeApi from "../payment/StripeApi";
import stripe from "../../assets/stripe.png";
import classiWallet from "../../assets/classi-WALLET.png";
import MainLoader from "../loader/MainLoader";

//geting the user data from local storage
const userData = JSON.parse(localStorage.getItem("realestatedata"));

const mapStateToProps = (store) => {
  const { PropertyReducer, AgentAgencyReducer } = store;
  return { PropertyReducer, AgentAgencyReducer };
};

const UpgradePostModal = ({
  setOpenUpgradeModal,
  openUpgradeModal,
  PropertyReducer,
  AgentAgencyReducer,
  postId,
  setPostId,
}) => {
  const dispatch = useDispatch();
  const { adupgradeloading, adDurationAndPaymentType } = PropertyReducer;
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [upgardeType, setUpgradeType] = useState(
    adDurationAndPaymentType?.durations?.length > 0
      ? adDurationAndPaymentType?.durations[0]
      : null
  );
  const isLarge = CurrentScreenSize();
  const [form] = Form.useForm();
  const [totalPrice, setTotalPrice] = useState(null);

  const [value, setValue] = useState(null);

  const handleOk = () => {
    setOpenUpgradeModal(false);
  };

  const handleCancel = () => {
    setOpenUpgradeModal(false);
  };
  const CheckboxGroup = Checkbox.Group;

  const fetchAdPaymentTypeDuration = () => {
    dispatch(
      allActions(
        {},
        {
          method: "get",
          endPoint: `ads_payment_types_duration?titles[]=Featured&titles[]=Urgent&titles[]=Spotlight&titles[]=Logo`,
          attempt: "FETCH_AD_PAYMENT_TYPE_DURATION_REQUEST_ATTEMPT",
          success: "FETCH_AD_PAYMENT_TYPE_DURATION_REQUEST_SUCCESS",
          failure: "FETCH_AD_PAYMENT_TYPE_DURATION_REQUEST_FAILURE",

          saveBearerToken: false,
          successInternalState: () => {},
          failureInternalState: () => {},
        }
      )
    );
  };
  useEffect(() => {
    fetchAdPaymentTypeDuration();
  }, []);

  useEffect(() => {
    upgardeType && form.setFieldValue({ duration: upgardeType?.no_of_days });
  }, [upgardeType]);

  const changePropertyUpgradeType = (value) => {
    adDurationAndPaymentType?.durations?.map((item) => {
      item?.no_of_days === value && setUpgradeType(item);
      return null;
    });
    form.setFieldValue("ad_status", []);
    setTotalPrice(0);
  };

  const calculateTotalPrice = () => {
    const fieldValue = form.getFieldValue("ad_status");
    let total = 0;
    upgardeType?.type?.map((item) => {
      fieldValue?.map((value) => {
        if (item?.cost_type === value) {
          total = total + Number(item?.price);
        }
        return null;
      });
      return null;
    });
    setTotalPrice((prev) => total);
  };

  const handleSubmitPayment = async (values) => {
    const featured = values?.ad_status?.find((item) => item === "Featured")
      ? 1
      : 0;
    const urgent = values?.ad_status?.find((item) => item === "Urgent") ? 1 : 0;
    const spot = values?.ad_status?.find((item) => item === "Spotlight")
      ? 1
      : 0;
    const logo = values?.ad_status?.find((item) => item === "Logo") ? 1 : 0;

    // dispatch({
    //   type: "LOADING_INQUIRY_PAYMENT",
    // });
    const newDate = new Date(values?.expiry_date);
    const year = newDate?.getFullYear();
    const month = newDate?.getMonth();
    if (value === 1) {
      setPaymentLoading(true);
      try {
        const stripeResponse = await StripeApi?.tokens({
          card: {
            number: values?.card_number,
            year: year,
            month: Number(month) < 10 ? `0${month}` : month,
            cvc: values?.cvc,
          },
        });

        if (!stripeResponse?.id) {
          throw new Error(stripeResponse);
        }

        const customerResposne = await StripeApi?.customers({
          id: stripeResponse?.id,
          email: userData?.user_email,
        });

        if (!customerResposne?.default_source) {
          throw new Error(customerResposne);
        }

        const chargeResponse = await StripeApi?.charges({
          postData: {
            amount: totalPrice,
          },
          orderId: {
            first_name: userData?.first_name,
            last_name: userData?.last_name,
            email: userData?.user_email,
          },
          customers: customerResposne?.id,
        });

        if (!chargeResponse?.data?.id) {
          throw new Error(chargeResponse);
        }
        setPaymentLoading(false);
        chargeResponse.status === 200 &&
          dispatch(
            allActions(
              {
                amount: totalPrice,
                paymethod: "Stripe",
                transaction_id: chargeResponse?.data?.id,
                duration_id: upgardeType?.duration_id,
                post_id: postId,
                featured: featured,
                urgent: urgent,
                spotlight: spot,
                logo: logo,
              },
              {
                method: "post",
                endPoint: "payment",
                attempt: "POST_UPGRADE_PROPERTY_CREDIT_DETAILS_ATTEMPT",
                success: "POST_UPGRADE_PROPERTY_CREDIT_DETAILS_SUCCESS",
                failure: "POST_UPGRADE_PROPERTY_CREDIT_DETAILS_FAILURE",

                saveBearerToken: false,
                successInternalState: () => {
                  form.resetFields();
                  setTimeout(() => {
                    setPostId("");
                    setOpenUpgradeModal(false);
                  }, 1000);
                },
                failureInternalState: () => {},
              }
            )
          );
      } catch (error) {
        setPaymentLoading(false);
        message.error(error?.response?.data?.error?.code);
      }
    } else {
      dispatch(
        allActions(
          {
            amount: totalPrice,
            paymethod: "classi_credit",
            transaction_id: "classi_credit",
            duration_id: upgardeType?.duration_id,
            post_id: postId,
            featured: featured,
            urgent: urgent,
            spotlight: spot,
            logo: logo,
          },
          {
            method: "post",
            endPoint: "payment",
            attempt: "POST_UPGRADE_PROPERTY_CREDIT_DETAILS_ATTEMPT",
            success: "POST_UPGRADE_PROPERTY_CREDIT_DETAILS_SUCCESS",
            failure: "POST_UPGRADE_PROPERTY_CREDIT_DETAILS_FAILURE",

            saveBearerToken: false,
            successInternalState: () => {
              form.resetFields();
              setTimeout(() => {
                setPostId("");
                setOpenUpgradeModal(false);
              }, 1000);
            },
            failureInternalState: () => {},
          }
        )
      );
    }
  };

  return (
    <div>
      {/* {(adupgradeloading || paymentLoading) && <MainLoader />} */}

      <Modal
        title="Upgrade Ad"
        onCancel={handleCancel}
        open={openUpgradeModal}
        onOk={handleOk}
        on
        footer={false}
        width={isLarge ? "70%" : "90%"}
      >
        <div
          style={{ marginTop: "1.5rem" }}
          className="upgrade--postmodal--container"
        >
          {!adupgradeloading && (
            <Form onFinish={handleSubmitPayment} layout="vertical" form={form}>
              <div className="upgrade--postmodal--featured--container">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <h3>Make Your Ad Stand Out!</h3>{" "}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "0.5rem",
                      fontWeight: 600,
                      fontSize: "0.9rem",
                      color: "#0b3e75ce",
                    }}
                  >
                    {totalPrice > 0 && <span>Price : {totalPrice} OR</span>}
                    {totalPrice > 0 && <span>{totalPrice * 10} Coin</span>}
                  </div>
                </div>
                <Divider style={{ margin: "0.5rem 0" }} />
                {adDurationAndPaymentType?.durations?.length > 0 && (
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Select Duration",
                      },
                    ]}
                    name="duration"
                  >
                    <Select
                      onChange={(value) => changePropertyUpgradeType(value)}
                      placeholder="Duration"
                      style={{
                        width: "10rem",
                      }}
                      options={[
                        ...adDurationAndPaymentType?.durations?.map((time) => {
                          return {
                            label: `${time?.no_of_days} days`,
                            value: time?.no_of_days,
                          };
                        }),
                      ]}
                    />
                  </Form.Item>
                )}
                {upgardeType?.type?.length > 0 && (
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Select the post upgrade type ",
                      },
                    ]}
                    name="ad_status"
                  >
                    <CheckboxGroup
                      onChange={calculateTotalPrice}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        padding: "0 1rem",
                        gap: "1rem",
                      }}
                      options={[
                        ...upgardeType?.type?.map((item) => {
                          return {
                            label: (
                              <strong>
                                <Tag
                                  color={
                                    item?.cost_type?.toLowerCase() ===
                                    "featured"
                                      ? "#1D5D9B"
                                      : item?.cost_type?.toLowerCase() ===
                                        "urgent"
                                      ? "#C51605"
                                      : item?.cost_type?.toLowerCase() ===
                                        "spotlight"
                                      ? "#0B666A"
                                      : item?.cost_type?.toLowerCase() ===
                                        "logo"
                                      ? "maroon"
                                      : ""
                                  }
                                >
                                  {item?.cost_type}
                                </Tag>
                                {item?.description
                                  ? item.description
                                  : item?.cost_type?.toLowerCase() ===
                                    "featured"
                                  ? `Have Your Ad Top Of The Category Listing $ ${item?.price}`
                                  : item?.cost_type?.toLowerCase() === "urgent"
                                  ? `Let People Know You Want To Sell, Rent Or Hire Quickly $ ${item?.price}`
                                  : item?.cost_type?.toLowerCase() ===
                                    "spotlight"
                                  ? `Have Your Ad Seen On The Classibazaar Home Page $ ${item?.price}`
                                  : item?.cost_type?.toLowerCase() === "logo"
                                  ? `Have Your Logo Seen  On The Classibazaar Jobs Listing Page $ ${item?.price}`
                                  : ""}
                              </strong>
                            ),
                            value: item?.cost_type,
                          };
                        }),
                      ]}
                    />
                  </Form.Item>
                )}
              </div>
              <div className="upgrade--postmodal--featured--container">
                <h3>Payment</h3>
                <Divider style={{ margin: "0.5rem 0" }} />
                <PaymentModalComponent value={value} setValue={setValue} />
                <Button
                  type="primary"
                  htmlType="submit"
                  className="button--modal"
                  style={{ width: "fit-content" }}
                >
                  Checkout Payment
                </Button>
              </div>
            </Form>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default connect(mapStateToProps)(UpgradePostModal);

export const PaymentModalComponent = ({ setValue, value }) => {
  const onChange = (e) => {
    setValue((prev) => e.target.value);
  };

  return (
    <div className="inquiry--credit--container--postUpgrade">
      <h3>Inquriy Credits ( AUD 1 = 10 Classi Credits)</h3>
      <Divider style={{ margin: "0.5rem 0" }} />
      <Form.Item
        rules={[
          {
            required: true,
            message: "Select payment method",
          },
        ]}
        label="Select Payment Type"
        name="payment_type"
      >
        <Radio.Group
          onChange={onChange}
          className="credit--card--layout--container"
        >
          <Radio value={1} className="credit--card--container">
            <img
              src={mastercard}
              alt=""
              style={{
                width: "11.8rem",
                height: "4.6rem",
                borderRadius: "0.5rem",
                marginLeft: "1rem",
                alignSelf: "center",
              }}
            />
          </Radio>
          <Radio className="credit--card--container" value={2}>
            <img
              src={classiWallet}
              alt=""
              style={{
                width: "12rem",
                borderRadius: "0.5rem",
                marginLeft: "1rem",
                height: "5rem",
              }}
            />
          </Radio>
        </Radio.Group>
      </Form.Item>

      {value === 1 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "1rem 0 0",
          }}
        >
          <Form.Item
            rules={[
              {
                required: true,
                message: "Card Name is required",
              },
            ]}
            label="Card Name"
            name="card_name"
          >
            <Input placeholder="Card Name" />
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "Card Number is required",
              },
            ]}
            label="Card Number"
            name="card_number"
          >
            <InputNumber
              style={{ width: "100%" }}
              placeholder="e.g 4242 4242 4242 4242"
            />
          </Form.Item>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "CVC number is required",
                },
              ]}
              style={{ flexBasis: "50%", width: "50%" }}
              label="CVC"
              name="cvc"
            >
              <InputNumber
                type="number"
                style={{ width: "50%" }}
                placeholder="e.g 123"
              />
            </Form.Item>
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Expiry Date is required",
                },
              ]}
              style={{ flexBasis: "50%", width: "50%" }}
              label="Expiry Date"
              name="expiry_date"
            >
              <DatePicker placeholder="MM-YYYY" picker="month" />
            </Form.Item>
          </div>
        </div>
      )}
    </div>
  );
};
